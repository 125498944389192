<div class="row user-admin-table">
  <div [ngStyle]="gridStyle" class="col mt-5">
    <ag-grid-angular [rowData]="distributionDef" class="ag-theme-balham pt-3"
      style="height: 100%;width: 100%;clear: both" [columnDefs]="columnDefs" [rowHeight]="rowHeight" [pagination]="true"
      [paginationPageSize]="paginationPageSize" [rowSelection]="rowSelection" (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged()" [getRowNodeId]="getRowNodeId">
    </ag-grid-angular>
  </div>
</div>

<div class="row user-admin-table" *ngIf="selectedRow">
  <div class="col mt-5">
    <ngb-tabset>
      <ngb-tab id="general" title="General">
        <ng-template ngbTabContent>
          <form name="generalForm" id="generalForm" #documentForm="ngForm" class="mt-5">
            <div class="row">
              <div *ngFor="let distribution of distributionList">
                <div class="form-group col-2 pl-5">
                  <input class="form-check-input" type="checkbox"
                    (change)="distribution.isActive = !distribution.isActive" [disabled]="!distribution.enabled"
                    name="recipient" [checked]="distribution.isActive">
                  <label class="form-check-label" [for]="distribution.channelName">
                    {{distribution.channelName}}
                  </label>
                </div>
              </div>
            </div>
            <div class="row form-group justify-content-center">
              <button type="button" class="btn btn-primary m-2" (click)="onSaveDistributor()">Update</button>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>