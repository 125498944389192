import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';

import { AlertService, ApiService, AuthenticationService, DataService } from '../../_services';
import { DocumentModel } from './document.model';
import { NgForm, FormControl } from '@angular/forms';
import { UtilityClass } from 'src/app/common/utility';


@Component({
  selector: 'app-select-bindings',
  templateUrl: './industryreportwithratig.component.html',
  styleUrls: ['./document.component.scss']
})
export class IndustryreportWithRatingComponent implements OnInit {
  formModel: DocumentModel;
  formEnabled: boolean;
  params: any;
  queryParams: any;
  selectedTab: String;
  showModel: any;
  comments: string;
  loggedInUserName: string;
  checkInDocument: any;
  checkInDocumentPath: string;
  defaultReportType: any;
  primaryIssuers: any;
  primarySector:any;
  secIssuers: any;
  industryIssuers = [];
  isPrimSecSame:boolean;

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {
    const self = this;
    this.showModel = true;
    this.formModel = new DocumentModel();
    this.formEnabled = false;
    this.isPrimSecSame = false;
    this.route.params.subscribe(params => (this.params = params));
    this.route.queryParams.subscribe(params => this.queryParams = params);
    this.checkInDocumentPath = 'Choose file';
    this.selectedTab = 'Metadata';
  }

  createDocument(event): void {
    const requestBody = {
      personid: this.authService.user.value.personID,
      industryid: event,
    };
    this.apiService
      .createDocument('industrywithrating', requestBody)
      .subscribe(response => {
        const queryParams = {
          documentid: response.data.documentID,
          productid: response.data.productID,
          industryid: response.data.industryID
        };
        this.router.navigate(['/industrywithratingreport/open'], { queryParams });
        this.setInitialData(response);
      });
  }

  saveOnTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      switch (this.selectedTab) {
        case 'Metadata':
          this.onSave();
        break;
        case 'Comments':
          this.onAddComment();
          break;
        case 'AuthorDocument':
          break;
        case 'DistributionRecipients':
          this.saveDistribution();
          break;
        default:
          break;
      }
    }
    this.selectedTab = tab;
  }

  reset() {
    this.formModel.actionNote.status.primaryAuthors = false;
    this.formModel.actionNote.status.productsStatus = false;
    this.formModel.actionNote.status.secondaryIssuers = false;
    this.formModel.actionNote.status.prodIssuersStatus = false;
    this.formModel.actionNote.status.secondaryIssuers = false;
    this.formModel.actionNote.status.secondaryIndustries = false;
    this.formModel.actionNote.status.primaryIssuers = false;
    this.formModel.actionNote.status.distributionStatus = false;
  }

  onSave() {
    this.formModel.actionNote.status.primaryAuthors = true;
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.status.secondaryIssuers = true;
    this.formModel.actionNote.status.prodIssuersStatus = true;
    this.formModel.actionNote.status.companyStatisticsStatus = true;
    this.formModel.actionNote.status.primaryIssuers = true;

    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    if(this.primarySector !=undefined){
      this.formModel.actionNote.primaryIndustries = [];
      this.formModel.actionNote.primaryIndustries.push(this.primarySector);
    }

    let count = 1;
    this.formModel.actionNote.secondaryProdIssuersList = this.secIssuers.map(secIss => ({
      issuer: secIss,
      isPrimary: false,
      issuerID: secIss.issuerID,
      productID: this.formModel.actionNote.productID,
      sequence: count++
    }));;

    count = 1;

    this.formModel.actionNote.primaryProdIssuersList = this.primaryIssuers.map(prIss => ({
      issuer: prIss,
      isPrimary: false,
      issuerID: prIss.issuerID,
      productID: this.formModel.actionNote.productID,
      sequence: count++
    }));

    this.checkBothIssuer();

    if (this.isPrimSecSame) {
      this.alertService.message('', 'Cannot use same issuer as both primary and secondary issuer. Please deselect the duplicate.');
      return;
    }

    this.apiService
      .updateDocument('industrywithrating', { companyNote: {}, industryNote: this.formModel.actionNote })
      .subscribe(null, null, () => this.reset());

  }

  onClose() {

    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.checkBothIssuer();

    if (this.isPrimSecSame) {
      this.alertService.message('', 'Cannot use same issuer as both primary and secondary issuer. Please deselect the duplicate.');
      return;
    }

    if ( !this.formModel.actionNote.readOnly ) {
      this.apiService
        .updateDocument('industrywithrating', { companyNote: {}, industryNote: this.formModel.actionNote })
        .subscribe(() => {
          this.apiService
            .documentAction('unlockdocument', {
              productid: this.formModel.actionNote.productID,
              personid: this.authService.user.value.personID
            })
            .subscribe(data => this.router.navigate(['/documents'], { queryParams: { stage: this.dataService.selectedStage } }));
        }, null, () => this.reset());
    } else {
      this.router.navigate(['/documents'], { queryParams: { stage: this.dataService.selectedStage } });
    }
  }

  getComment(): string {
    const timeStamp = new Date();
    let comment = '';
    const previousComments = this.formModel.actionNote.product.comments || '';
    const currentComments = this.comments ? `${this.loggedInUserName} ${timeStamp.toString().substr(0, 33)}  :  ${this.comments}` : '';
    if (previousComments === '') {
      comment = `${currentComments}`;
    } else {
      comment = `${previousComments} <br />${currentComments}`;
    }
    this.formModel.actionNote.product.comments = comment;
    return comment;
  }

  onAddComment() {
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.product.comments = this.getComment();

    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.apiService
      .updateDocument('industrywithrating', { companyNote: {}, industryNote: this.formModel.actionNote })
      .subscribe(data => this.comments = '', null, this.reset);
  }

  setInitialData({ data }) {
    this.formModel.actionNote = data;
    this.formModel.actionNote.primaryProdIssuersList.defaultCurrency = this.formModel.actionNote.primaryProdIssuersList.defaultCurrency || 'USD';
    this.formModel.selectedPrimaryIssuer = this.formModel.actionNote.prodIndustries.reportSeries.id;
    this.formEnabled = true;
    this.formModel.actionNote.personID = this.authService.user.value.personID;
    this.formModel.primaryAuthors = data.allAnalyst;
    this.formModel.actionNote.prodIndustries.reportTypeId = this.formModel.reportTypeList.some(function (value) { return value.id === data.prodIndustries.reportTypeId }) ?
      data.prodIndustries.reportType.id : (this.defaultReportType ? this.defaultReportType.id : null);

    this.primaryIssuers = this.formModel.actionNote.primaryProdIssuersList.map(issuer => issuer.issuer);

    this.secIssuers = this.formModel.actionNote.secondaryProdIssuersList.map(issuer => issuer.issuer);

    this.primarySector = this.formModel.actionNote.primaryIndustries[0];

  }

  ngOnInit(): void {
    this.authService.user.subscribe(user => this.loggedInUserName = user.displayName);
    this.apiService.getPrimaryIssuers(this.authService.user.value.personID).subscribe(({ data }) => (this.formModel.primaryIssuers = data));
    this.apiService.getReportTypeSeries('reportseries', true).subscribe(({ data }) => this.formModel.reportSeriesList = data);
    this.apiService.getPrimaryIndusties(this.authService.user.value.personID).subscribe((res) => {
      this.formModel.primaryIndustries = res.data;
    })
    this.apiService.getReportTypeSeries('reporttype', true).subscribe(({ data }) => {
      this.formModel.reportTypeList = data;
      this.defaultReportType = data.find(row => row.value === 'Company-Specific');
      if (this.params.action === 'open') {
        const requestBody = { ...this.queryParams, personid: this.authService.user.value.personID };
        this.apiService.openDocument('industrywithrating', requestBody).subscribe(data => {
          this.setInitialData(data);
        }, err => this.router.navigate(['/documents']));
      }
    });
  }

  getBlankTemplate(): void {
    this.apiService.downloadDocument('industrywithrating', this.formModel.actionNote.documentID).subscribe(response => {
      const blob = new Blob([response.body], { type: 'application/msword' });
      let util = new UtilityClass();
      util.downloadDocument(response, this.formModel.actionNote.documentID);
    });
  }

  checkOutDocument(): void {
    this.apiService.downloadDocument('downloaddocument', this.formModel.actionNote.documentID).subscribe(response => {
      let util = new UtilityClass();
      util.downloadDocument(response, this.formModel.actionNote.documentID);
    });
  }

  onFileChange(event, fileInput): void {
    this.checkInDocument = fileInput.files[0];
    this.checkInDocumentPath = fileInput.files[0].name;
    fileInput.value = null;
  }

  checkIn(): void {
    const formData = new FormData();
    formData.append('uploadDocument', this.checkInDocument);
    this.apiService.uploadDocument(this.formModel.actionNote.documentID, formData).subscribe(() => {
      this.checkInDocument = null;
      this.checkInDocumentPath = 'Choose file';
    });

  }

  enableTab(tab): boolean {
    return !(tab === 'distribution' && this.formModel.actionNote.stageID === 4);
  }

  editIssuerData(content) {
    this.apiService.fetchIssuerDetails(this.formModel.actionNote.productID).subscribe(({ data }) => {
      this.industryIssuers = data;
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', container: '#viewEditIssuers' }).result.then((result) => {

        this.apiService.updateIssuerDetails(this.formModel.actionNote.productID, this.industryIssuers).subscribe();
      }, (reason) => {
        // Dismissed
      })
    })
  }

  isIssuerSaveDisabled(form): boolean {
    return form.invalid;
  }

  saveDistribution() {
    this.formModel.actionNote.status.distributionStatus = true;

    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }
    this.apiService.updateDocument('industrywithrating', { companyNote: {}, industryNote: this.formModel.actionNote })
      .subscribe(null, null, this.reset);
  }

  checkBothIssuer() {
    this.isPrimSecSame = false;
    this.formModel.actionNote.secondaryProdIssuersList.forEach(issuer => {
      this.formModel.actionNote.primaryProdIssuersList.forEach(pIssuer => {
        if (issuer.issuerID === pIssuer.issuerID) {
          this.isPrimSecSame = true;
        }
      });
    });
  }


}
