<div class="user-admin-table row justify-content-between" *ngIf="!createView">
  <div class="mt-3">
    Page Size:
    <select (change)="onPageSizeChanged($event)" id="page-size">
      <option selected="" value="20">20</option>
      <option value="100">100</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
    </select>
  </div>
  <div class="mt-3 w-25">
    <input type="text" class="form-control" placeholder="Quick search" (keyup)="onQuickFilterChanged($event)">
  </div>
  <div [ngStyle]="gridStyle" class="w-100 istable">
    <ag-grid-angular [rowData]="userDef" class="ag-theme-balham pt-3" style="height: 100%;width: 100%;clear: both"
                     [columnDefs]="columnDefs" [rowHeight]="rowHeight" [pagination]="true" [paginationPageSize]="paginationPageSize"
                     [rowSelection]="rowSelection" (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()" [getRowNodeId]="getRowNodeId">
    </ag-grid-angular>
  </div>
</div>

<div class="row user-admin-table" *ngIf="selectedRow">
  <div class="col mt-5">
    <ngb-tabset (tabChange)="saveOnTabChange($event.nextId)">
      <ngb-tab id="general" title="General">
        <ng-template ngbTabContent>
          <form name="form" id="generalForm" #documentForm="ngForm" class="pt-5 m-auto w-75">
            <div class="form-group row">
              <div class="col-sm-2">
                <label for="active" class="col-form-label">Active</label>
                <input type="checkbox" class="align-text-bottom ml-3" id="active" name="active" [(ngModel)]="selectedRow.isActive">
              </div>
            </div>
            <div class="form-group row">
              <label for="firstName" class="col-sm-2 col-form-label">First Name<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="selectedRow.givenName" #firstName="ngModel" required>
                <div [hidden]="firstName.valid || firstName.pristine" class="invalid-feedback">
                  First Name is required
                </div>
              </div>

              <label for="mi" class="col-sm-2 col-form-label">Middle Name</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="mi" name="mi" [(ngModel)]="selectedRow.middleName">
              </div>

            </div>

            <div class="form-group row">
              <label for="lastName" class="col-sm-2 col-form-label">Last Name<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="selectedRow.familyName" #lastName="ngModel" required>
                <div [hidden]="lastName.valid || lastName.pristine" class="invalid-feedback">
                  Last Name is required
                </div>
              </div>
              <label for="jobTitle" class="col-sm-2 col-form-label">Title</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="jobTitle" name="jobTitle" [(ngModel)]="selectedRow.jobTitle">
              </div>
            </div>
            <div class="form-group row">
              <label for="displayName" class="col-sm-2 col-form-label">Display Name<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="displayName" name="displayName" [(ngModel)]="selectedRow.displayName" #displayName="ngModel" required>
                <div [hidden]="displayName.valid || displayName.pristine" class="invalid-feedback">
                  Display Name is required
                </div>
              </div>
              <label for="loginName" class="col-sm-2 col-form-label">Login name<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="loginName" name="loginName" [(ngModel)]="selectedRow.username" #loginName="ngModel" required>
                <div [hidden]="loginName.valid || loginName.pristine" class="invalid-feedback">
                  Login Name is required
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="password" class="col-sm-2 col-form-label">Password<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="password" class="form-control" id="password" name="password" [(ngModel)]="selectedRow.password" #password="ngModel" required >
                <div [hidden]="password.valid" class="invalid-feedback">
                  Password is required
                </div>
              </div>
              <label for="sector" class="col-sm-2 col-form-label">Sectors</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="sector" name="sector" [(ngModel)]="selectedRow.address1">
              </div>
            </div>
            <div class="form-group row">
              <label for="city" class="col-sm-2 col-form-label">City</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="city" name="city" [(ngModel)]="selectedRow.city">
              </div>
              <label for="state" class="col-sm-2 col-form-label">State/Province</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="state" name="state" [(ngModel)]="selectedRow.state_Region">
              </div>
            </div>
            <div class="form-group row">
              <label for="postal" class="col-sm-2 col-form-label">Zip/Postal code</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="postal" name="postal" [(ngModel)]="selectedRow.postalCode">
              </div>
              <label for="mailstop" class="col-sm-2 col-form-label">Mailstop</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="mailstop" name="mailstop" [(ngModel)]="selectedRow.division">
              </div>
            </div>
            <div class="form-group row">
              <label for="phone" class="col-sm-2 col-form-label">Phone</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="selectedRow.workPhone" #phone="ngModel" >
              </div>
              <label for="fax" class="col-sm-2 col-form-label">Fax</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="fax" name="fax" [(ngModel)]="selectedRow.workFax">
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col-sm-2 col-form-label">Email<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="email" name="email" [(ngModel)]="selectedRow.workEmail" #email="ngModel" required>
                <div [hidden]="email.valid || email.pristine" class="invalid-feedback">
                  Email is required
                </div>
              </div>
              <label for="fcrd" class="col-sm-2 col-form-label">FCRD ID#</label>
              <div class="col-sm-4">
                <input class="form-control" type="text" id="fcrd" name="fcrd" [(ngModel)]="selectedRow.fcrd">
              </div>
            </div>
            <div class="form-group row">
              <label for="rating" class="col-sm-2 col-form-label">Starmine Rating</label>
              <div class="col-sm-4">
                <input class="form-control" type="text" id="rating" name="rating" [(ngModel)]="selectedRow.analystRating">
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <button type="button" class="btn btn-primary m-2" (click)="onSaveUserDetails()" [disabled]="!documentForm.form.valid || !emailValidator(email)">
                {{actionLabel}}
              </button>
            </div>

          </form>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="permission" title="Permission" [disabled]="!rootPermission">
        <ng-template ngbTabContent>
          <form name="form" id="permissionForm" #documentForm="ngForm" class="mt-5">
            <div class="container">
              <div class="row justify-content-end">
                <input type="text" class="form-control w-25 mr-5" placeholder="Quick search" name="searchPermission" [(ngModel)]="searchPermission">
                <button type="submit" class="btn btn-primary" (click)="onUpdateUserPermission()">Update</button>
              </div>
              <div class="form-group row">
                <div class="col-sm-2">
                  <label for="superAdmin" class="col-form-label">Super Admin</label>
                  <input type="checkbox" class="align-text-bottom ml-3" id="superAdmin" name="superAdmin" [(ngModel)]="rootPermission.sapermission">
                </div>
                <div class="col-sm-2">
                  <label for="passwordAdmin" class="col-form-label">Password Admin</label>
                  <input type="checkbox" class="align-text-bottom ml-3" id="passwordAdmin" name="passwordAdmin" [(ngModel)]="rootPermission.papermission">
                </div>
              </div>
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-2">
                  <h5 class="pl-3">Authoring</h5>
                  <div class="row pt-2">
                    <div class="pl-3">
                      <span>C</span>
                    </div>
                    <div class="pl-4">
                      <span>E</span>
                    </div>
                    <div class="pl-4">
                      <span>V</span>
                    </div>
                    <div class="pl-4">
                      <span>A</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <h5 class="pl-2">Editing</h5>
                  <div class="row pt-2">
                    <div class="pl-3">
                      <span>E</span>
                    </div>
                    <div class="pl-4">
                      <span>V</span>
                    </div>
                    <div class="pl-4">
                      <span>A</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <h5 class="pl-0">Compliance</h5>
                  <div class="row pt-2">
                    <div class="pl-3">
                      <span>E</span>
                    </div>
                    <div class="pl-4">
                      <span>V</span>
                    </div>
                    <div class="pl-4">
                      <span>A</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <h5 class="pl-0">Production</h5>
                  <div class="row pt-2">
                    <div class="pl-3">
                      <span>E</span>
                    </div>
                    <div class="pl-4">
                      <span>V</span>
                    </div>
                    <div class="pl-4">
                      <span>A</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-md-4">All Sectors</div>
                <div class="col-md-2 text-center">
                  <div class="row">
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.Create"
                             (change)="onPermissionChange(rootPermission, 'Authoring', 'Create', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.Edit"
                             (change)="onPermissionChange(rootPermission, 'Authoring', 'Edit', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.View"
                             (change)="onPermissionChange(rootPermission, 'Authoring', 'View', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.Approve"
                             (change)="onPermissionChange(rootPermission, 'Authoring', 'Approve', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 text-center">
                  <div class="row">
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Editing.Edit"
                             (change)="onPermissionChange(rootPermission, 'Editing', 'Edit', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Editing.View"
                             (change)="onPermissionChange(rootPermission, 'Editing', 'View', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Editing.Approve"
                             (change)="onPermissionChange(rootPermission, 'Editing', 'Approve', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 text-center">
                  <div class="row">
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Compliance.Edit"
                             (change)="onPermissionChange(rootPermission, 'Compliance', 'Edit', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Compliance.View"
                             (change)="onPermissionChange(rootPermission, 'Compliance', 'View', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Compliance.Approve"
                             (change)="onPermissionChange(rootPermission, 'Compliance', 'Approve', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 text-center">
                  <div class="row">
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Production.Edit"
                             (change)="onPermissionChange(rootPermission, 'Production', 'Edit', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Production.View"
                             (change)="onPermissionChange(rootPermission, 'Production', 'View', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                    <div class="pl-3">
                      <input type="checkbox" [checked]="rootPermission.stagePermission.Production.Approve"
                             (change)="onPermissionChange(rootPermission, 'Production', 'Approve', PERMISSION_LEVEL.ROOT)"/>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let sector of rootPermission.permission | permissionFilter: searchPermission">
                <div class="row pt-3 sector-permission">
                  <div class="col-md-4">
                    <i class="fa" [ngClass]="sector.toggleIcon" (click)="onToggleExpand(sector)"></i>
                    <span>{{sector.sectorName}}</span>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Authoring.Create"
                               (change)="onPermissionChange(sector, 'Authoring', 'Create', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Authoring.Edit"
                               (change)="onPermissionChange(sector, 'Authoring', 'Edit', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Authoring.View"
                               (change)="onPermissionChange(sector, 'Authoring', 'View', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Authoring.Approve"
                               (change)="onPermissionChange(sector, 'Authoring', 'Approve', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Editing.Edit"
                               (change)="onPermissionChange(sector, 'Editing', 'Edit', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Editing.View"
                               (change)="onPermissionChange(sector, 'Editing', 'View', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Editing.Approve"
                               (change)="onPermissionChange(sector, 'Editing', 'Approve', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Compliance.Edit"
                               (change)="onPermissionChange(sector, 'Compliance', 'Edit', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Compliance.View"
                               (change)="onPermissionChange(sector, 'Compliance', 'View', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Compliance.Approve"
                               (change)="onPermissionChange(sector, 'Compliance', 'Approve', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Production.Edit"
                               (change)="onPermissionChange(sector, 'Production', 'Edit', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Production.View"
                               (change)="onPermissionChange(sector, 'Production', 'View', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="sector.sectorPermission.Production.Approve"
                               (change)="onPermissionChange(sector, 'Production', 'Approve', PERMISSION_LEVEL.SECTOR)"/>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="sector.issuerPermission && sector.expand">
                  <div class="row pt-3 issuer-permission" *ngFor="let issuer of sector.issuerPermission">
                    <div class="col-md-4 pl-md-5">{{issuer.issuerName}}</div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.Create"
                                 (change)="onPermissionChange(issuer, 'Authoring', 'Create', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.Edit"
                                 (change)="onPermissionChange(issuer, 'Authoring', 'Edit', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.View"
                                 (change)="onPermissionChange(issuer, 'Authoring', 'View', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.Approve"
                                 (change)="onPermissionChange(issuer, 'Authoring', 'Approve', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Editing.Edit"
                                 (change)="onPermissionChange(issuer, 'Editing', 'Edit', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Editing.View"
                                 (change)="onPermissionChange(issuer, 'Editing', 'View', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Editing.Approve"
                                 (change)="onPermissionChange(issuer, 'Editing', 'Approve', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Compliance.Edit"
                                 (change)="onPermissionChange(issuer, 'Compliance', 'Edit', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Compliance.View"
                                 (change)="onPermissionChange(issuer, 'Compliance', 'View', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Compliance.Approve"
                                 (change)="onPermissionChange(issuer, 'Compliance', 'Approve', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Production.Edit"
                                 (change)="onPermissionChange(issuer, 'Production', 'Edit', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Production.View"
                                 (change)="onPermissionChange(issuer, 'Production', 'View', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="issuer.issuerPermission.Production.Approve"
                                 (change)="onPermissionChange(issuer, 'Production', 'Approve', PERMISSION_LEVEL.ISSUER, sector)"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div class="row justify-content-center pt-3 pb-3">
                <button type="submit" class="btn btn-primary" (click)="onUpdateUserPermission()">Update</button>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>


