import {Component} from '@angular/core';

@Component({
  templateUrl: '404.component.html',
  styleUrls: ['404.component.scss']
})
export class NotFoundComponent {
  constructor() {
  }
}
