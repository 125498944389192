export class AdminPanel {
  icon: string;
  iconUrl: string;
  name: string;
  description: string;
  options: any[];

  constructor(
    icon: string,
    iconUrl: string,
    name: string,
    description: string,
    options: any[]
  ) {
    this.icon = icon;
    this.iconUrl = iconUrl;
    this.name = name;
    this.description = description;
    this.options = options;
  }
}
