import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PasswordValidation: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const currentPassword = control.get('oldPassword');
  const password = control.get('newPassword'); // to get value in input tag
  const confirmPassword = control.get('confirmPassword'); // to get value in input tag
  const error = { MatchPassword: true, message: control.errors ? control.errors.message : '' };
  let isInvalid = !(currentPassword.value || password.value || confirmPassword.value) && control.errors && control.errors.MatchPassword;
  if (currentPassword.value && password.value && currentPassword.value === password.value) {
    isInvalid = true;
    error.message = 'Current and new password cannot be same';
  } else if (password.value && confirmPassword.value && password.value !== confirmPassword.value) {
    isInvalid = true;
    error.message = 'New and confirm password should be same';
  }

  return isInvalid ? error : null;
};
