<div class="user-admin-table row justify-content-between" *ngIf="!createView">
  <div class="mt-3">
    Page Size:
    <select (change)="onPageSizeChanged($event)" id="page-size">
      <option selected="" value="20">20</option>
      <option value="100">100</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
    </select>
  </div>
  <div class="mt-3 w-25">
    <input type="text" class="form-control" placeholder="Quick search" (keyup)="onQuickFilterChanged($event)">
  </div>
  <div [ngStyle]="gridStyle" class="w-100 istable">
    <ag-grid-angular [rowData]="issuerDef" class="ag-theme-balham pt-3" style="height: 100%;width: 100%;clear: both"
      [columnDefs]="columnDefs" [rowHeight]="rowHeight" [pagination]="true" [paginationPageSize]="paginationPageSize"
      [rowSelection]="rowSelection" (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()"
      [getRowNodeId]="getRowNodeId">
    </ag-grid-angular>
  </div>
</div>

<div class="row issuer-admin-table" *ngIf="selectedRow">
  <div class="col mt-5">
    <ngb-tabset>
      <ngb-tab title="General">
        <ng-template ngbTabContent>
          <form name="generalForm" id="generalForm" #documentForm="ngForm" class="pt-5 m-auto">

            <div class="form-group row">
              <div class="col-sm-2">
                <label for="active" class="col-form-label">Active</label>
                <input type="checkbox" class="align-text-bottom ml-3" id="active" name="active"
                  [(ngModel)]="selectedRow.isActive">
              </div>
            </div>
            <div class="form-group row">
              <label for="issuerName" class="col-sm-2 col-form-label">Display Name<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="issuerName" name="issuerName"
                  [(ngModel)]="selectedRow.issuerName" #displayName="ngModel" required>
                <div [hidden]="displayName.valid || displayName.pristine" class="invalid-feedback">
                  Display Name is required
                </div>
              </div>
              <label for="priceChartIdentifier" class="col-sm-2 col-form-label">Price Chart Identifier<span class="required">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="priceChartIdentifier" name="priceChartIdentifier"
                  [(ngModel)]="selectedRow.priceChartId" #priceChartIdentifier="ngModel" required>
                <div [hidden]="priceChartIdentifier.valid || priceChartIdentifier.pristine" class="invalid-feedback">
                  Price Chart Identifier is required
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="legalName" class="col-sm-2 col-form-label">Legal Name</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="legalName" name="legalName"
                  [(ngModel)]="selectedRow.legalName">
              </div>
              <label for="tradingName" class="col-sm-2 col-form-label">Trading Name</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="tradingName" name="tradingName"
                  [(ngModel)]="selectedRow.tradingName">
              </div>
            </div>
            <div class="form-group row">
              <label for="localName" class="col-sm-2 col-form-label">Local Name</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="localName" name="localName"
                  [(ngModel)]="selectedRow.localName">
              </div>
              <label for="parentName" class="col-sm-2 col-form-label">Parent Name</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" id="parentName" name="parentName"
                  [(ngModel)]="selectedRow.parentName">
              </div>
            </div>

            <div class="form-group row">
              <label for="primaryIndustry" class="col-sm-2 col-form-label">Primary Industry<span class="required">*</span></label>
              <div class="col-sm-4">
                <ng-select name="primaryIndustry" id="primaryIndustry" [items]="industryDef"
                  [(ngModel)]="selectedRow.primaryIndustryId" #primaryIndustry="ngModel" required
                  bindLabel="industryName" bindValue="industry">
                </ng-select>
                <div [hidden]="primaryIndustry.valid || primaryIndustry.pristine" class="invalid-feedback">
                  Primary Sector is required
                </div>
              </div>
              <label for="country" class="col-sm-2 col-form-label">Country Code<span class="required">*</span></label>
              <div class="col-sm-4">
                <ng-select name="country" id="country" [items]="countryNames" [(ngModel)]="selectedRow.country"
                  #country="ngModel" required>
                </ng-select>
                <div [hidden]="country.valid || country.pristine" class="invalid-feedback">
                  Country Code is required
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-6">
                <div class="form-group row">
                  <label for="ric" class="col-sm-4 col-form-label">Cusip</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="cusip" name="cusip"
                      [(ngModel)]="selectedRow.cusip" #cusip="ngModel">
                      <!-- <div [hidden]="cusip.valid || cusip.pristine" class="invalid-feedback">
                        Cusip is required
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <fieldset class="the-fieldset col-sm-6">
                <legend class="the-legend">Fiscal Year<span class="required">*</span></legend>
                <div class="row">
                  <div class="col-sm-8">
                    <div class="form-check m-3">
                      <input class="form-check-input" type="radio" name="fiscalYear" id="calendarYear" [value]="true"
                        [(ngModel)]="selectedRow.fiscalUsesCalendar">
                      <label class="form-check-label align-middle" for="calendarYear">
                        Use Calendar Year
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-check m-3">
                      <input class="form-check-input" type="radio" name="fiscalYear" id="endOfMonth" [value]="false"
                        [(ngModel)]="selectedRow.fiscalUsesCalendar">
                      <label class="form-check-label align-middle" for="endOfMonth">End of</label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <ng-select class="form-check-label" [items]="monthsList" name="month"
                      [(ngModel)]="selectedRow.fiscalYearEnd" #fiscalMonth="ngModel"
                      [required]="!selectedRow.fiscalUsesCalendar"></ng-select>
                    <div [hidden]="fiscalMonth.valid || fiscalMonth.pristine" class="invalid-feedback">
                      Month is required
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="col-sm-6">
                <div class="form-group row">
                  <label for="logo" class="col-sm-4 col-form-label">Logo URL</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="logo" name="logo" [(ngModel)]="selectedRow.logoURL">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="ric" class="col-sm-4 col-form-label">Related Disclosures</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="relatedDisclosures" name="relatedDisclosures"
                      [(ngModel)]="selectedRow.relatedDisclosures">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="ric" class="col-sm-4 col-form-label">Reuters Industry</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="reutersIndustry" name="reutersIndustry"
                      [(ngModel)]="selectedRow.reutersIndustry">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="ric" class="col-sm-4 col-form-label">Bberg Industry</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="bbergIndustry" name="bbergIndustry"
                      [(ngModel)]="selectedRow.bbergIndustry">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <fieldset class="the-fieldset col-sm-6">
                <legend class="the-legend">Security</legend>
                <div class="row">
                  <label for="securityName" class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="securityName" name="securityName"
                      [(ngModel)]="selectedRow.securityName">
                  </div>
                </div>
              </fieldset>
              <div class="col-sm-6">
                <div class="form-group row">
                  <label for="ric" class="col-sm-4 col-form-label">FCRD Industry</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="fcrdIndustry" name="fcrdIndustry"
                      [(ngModel)]="selectedRow.fcrdIndustry">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <fieldset class="the-fieldset w-100">
                <legend class="the-legend">Security Tickers</legend>
                <div class="row">
                  <div class="col-sm-1">
                    <span>Primary</span>
                  </div>
                  <div class="col-sm-2">
                    <span>Name</span>
                  </div>
                  <div class="col-sm-2">
                    <span>Country</span>
                  </div>
                  <div class="col-sm-3">
                    <span>Exchange</span>
                  </div>
                  <div class="col-sm-3">
                    <span>Symbol Set</span>
                  </div>
                  <div class="col-sm-1">
                    <span>Active</span>
                  </div>
                </div>
                <div class="row mt-3" *ngFor="let ticker of tickerDef; index as i">
                  <div class="col-sm-1">
                    <input class="ml-2 mt-2" type="radio" name="primary" [checked]="ticker.isPrimary"
                      (change)="onPrimaryChange(ticker)">
                  </div>
                  <div class="col-sm-2">
                    <input type="text" class="form-control" id="tickerName" name="{{'tickerName' + i}}"
                      [(ngModel)]="ticker.ticker">
                  </div>
                  <div class="col-sm-2">
                    <ng-select class="form-check-label w-75" [items]="exchangeCountries" name="{{'countryCode' + i}}"
                      [(ngModel)]="ticker.country"></ng-select>
                  </div>
                  <div class="col-sm-3">
                    <ng-select class="form-check-label w-75" [items]="exchangeChoice" name="{{'exchange' + i}}"
                      bindLabel="tradingExchange" bindValue="exchangeID" [(ngModel)]="ticker.exchangeID"></ng-select>
                  </div>
                  <div class="col-sm-3">
                    <ng-select class="form-check-label w-75" [items]="symbolSet" name="{{'symbolSet' + i}}"
                      [(ngModel)]="ticker.symbolSet"></ng-select>
                  </div>
                  <div class="col-sm-1">
                    <input type="checkbox" name="{{'active' + i}}" [(ngModel)]="ticker.isActive">
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="form-group row justify-content-center">
              <div placement="top">
                <button type="button" class="btn btn-primary m-2" (click)="onSaveIssuer()"
                  [disabled]="!documentForm.form.valid">
                  {{actionLabel}}
                </button>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>