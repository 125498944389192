import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { DataService } from '../_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-open-cell',
  template: `<app-document-open-action [params]="params"></app-document-open-action> `
})
export class DocumentOpenRendererComponent implements ICellRendererAngularComp {
  public params: any;

  constructor(private dataService: DataService, private router: Router) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  

}
