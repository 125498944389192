<div class="user-admin-table row justify-content-between
" *ngIf="!createView">
  <div class="mt-3">
    Page Size:
    <select (change)="onPageSizeChanged($event)" id="page-size">
      <option selected="" value="20">20</option>
      <option value="100">100</option>
      <option value="500">500</option>
      <option value="1000">1000</option>
    </select>
  </div>
  <div class="mt-3 w-25">
    <input type="text" class="form-control" placeholder="Quick search" (keyup)="onQuickFilterChanged($event)">
  </div>
  <div [ngStyle]="gridStyle" class="w-100 istable">
    <ag-grid-angular [rowData]="industryDef" class="ag-theme-balham pt-3" style="height: 100%;width: 100%;clear: both"
                     [columnDefs]="columnDefs" [rowHeight]="rowHeight" [pagination]="true" [paginationPageSize]="paginationPageSize"
                     [rowSelection]="rowSelection" (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()" [getRowNodeId]="getRowNodeId">
    </ag-grid-angular>
  </div>
</div>

<div class="row user-admin-table" *ngIf="selectedRow">
  <div class="col mt-5">
    <ngb-tabset>
      <ngb-tab id="general" title="General">
        <ng-template ngbTabContent>
          <form name="generalForm" id="generalForm" #documentForm="ngForm" class="pt-5 m-auto w-50">
            <div class="form-group row">
              <div class="col-sm-2">
                <label for="active" class="col-form-label">Active</label>
                <input type="checkbox" class="align-text-bottom ml-3" id="active" name="active" [(ngModel)]="selectedRow.isActive">
              </div>
            </div>
            <div class="form-group row">
              <label for="sectorName" class="col-sm-3 col-form-label">Industry Name<span class="required">*</span></label>
              <div class="col-sm-6">
                <input type="text" class="form-control" id="sectorName" name="sectorName" [(ngModel)]="selectedRow.industryName" required #sectorName="ngModel">
                <div [hidden]="sectorName.valid || sectorName.pristine" class="invalid-feedback">
                  Industry name is required
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="mailing" class="col-sm-3 col-form-label">Mailing List Email <Address></Address></label>
              <div class="col-sm-6">
                <input type="text" class="form-control" name="mailing" [(ngModel)]="selectedRow.mailingListPrefix">
                <div>Examples: "Biotechnology@zelman.com", "FinancialServices@zelman.com" etc.</div>
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <button type="button" class="btn btn-primary m-2" (click)="onSaveIndustry()" [disabled]="documentForm.invalid">{{actionLabel}}</button>
            </div>
          </form>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
