import {Component, OnInit} from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../../../_services';

@Component({
  templateUrl: 'ratings.distribution.component.html',
  styleUrls: ['../admin.component.scss']
})
export class RatingsDistributionComponent implements OnInit{

  date: NgbDateStruct;
  gridStyle = {
    height: '200px',
    width: '600px'
  };
  rowData: any;
  columnDefs = [{
    headerName: '',
    field: 'label', width: 150
    // children: [
    //   {headerName: '', field: 'label', width: 150},
    // ]
  }, {
    headerName: 'All Covered Companies',
    field: 'r1', width: 150, editable: true, cellEditor: 'agTextCellEditor'
    // children: [
    //   {headerName: '', field: 'r1', width: 100, editable: true, cellEditor: 'agTextCellEditor'},
    //   {headerName: '', field: '', width: 100, editable: true, cellEditor: 'agTextCellEditor'},
    // ]
  }, {
    headerName: 'Investment Banking Clients',
    field: 'r2', width: 175, editable: true, cellEditor: 'agTextCellEditor'
    // children: [
    //   {headerName: '', field: 'r2', width: 175, editable: true, cellEditor: 'agTextCellEditor'},
    //   {headerName: '', field: '', width: 175, editable: false, cellEditor: 'agTextCellEditor'},
    // ]
  }];
  gridApi: any;
  gridColumnApi: any;
  gridOptions: any;

  constructor(private calendar: NgbCalendar, private adminService: AdminService ) {
    const self = this;
    this.date = this.calendar.getToday();
    this.gridOptions = {
      stopEditingWhenGridLosesFocus: true
    };
    
  }
  ngOnInit() {
    this.adminService.ratingsDistribution('data', {}).subscribe(response => {
      const date = response.ratingDate.split('-').map(d => Number(d));
      this.customeDate('split', date);
      this.rowData = response.ratingDistributions;
    });
  }

  onGridReady(params: { api: { sizeColumnsToFit: () => void; getSelectedRows: () => any[]; getRowNode: (arg0: any) => any; }; }) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

  }

  resetHeight() {
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 320;
    /*Get the HOT length*/
    const hotData = this.rowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * 28) + 80;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  updateRatings(): void {
    this.adminService.ratingsDistribution('update', {
      ratingDate: this.customeDate('join', this.date),
      ratingDistributions: this.rowData
    }).subscribe();
  }


  customeDate(type, date) {
    if( type === 'split' ) {
      this.date = { year: date[0], month: date[1], day: date[2] };
    } else if ( type === 'join' ) {
      return date.year.toString().concat('-', date.month.toString(), '-', date.day.toString());
    }
  }


}
