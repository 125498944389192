import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class NotificationService {
    private notifications = new Subject<{ message: string, type: 'success' | 'error' }>();
  
    getNotification() {
      return this.notifications.asObservable();
    }
  
    showSuccess(message: string) {
      this.notifications.next({ message, type: 'success' });
    }
  
    showError(message: string) {
      this.notifications.next({ message, type: 'error' });
    }
  }
  