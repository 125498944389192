import { Component, OnInit } from '@angular/core';
import { AdminService, DataService } from 'src/app/_services';
import { AdminPanel } from '../../common/AdminPanel';
import {saveAs} from 'file-saver';

@Component({
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss']
})
export class ReportComponent implements OnInit {
  panels = [
    {
      icon: 'fa-sitemap',
      iconUrl: '/reports',
      name: 'Workflow History',
      description: 'Click on the link below to download the Workflow History',
      options: [
        {
          optionName: 'View Workflow History',
          optionUrl: '/reports',
          enabled: true
        },
      ]
    },
    {
      icon: 'fa-bar-chart',
      iconUrl: '/reports',
      name: 'Rating Changes',
      description: 'Click on the link below to download the Rating Changes',
      options: [
        {
          optionName: 'View Rating Changes',
          optionUrl: '/reports',
          enabled: true
        }
      ]
    }
  ];
  adminPanels = [];

  constructor(private adminService: AdminService, private dataService: DataService) {
  }

  addAdminPanel() {
    this.panels.forEach(panel => {
      this.adminPanels.push(
        new AdminPanel(
          panel.icon,
          panel.iconUrl,
          panel.name,
          panel.description,
          panel.options
        )
      );
    });
  }

  ngOnInit() {
    this.addAdminPanel();
  }

  downloadReport(event) {
    if (event.optionName === 'View Workflow History') {

    } else {

    }
    this.adminService.downloadReport(event.optionName === 'View Workflow History' ? 'downloadworkflowhistory' : 'downloadratingchanges').subscribe(response => {
      const blob = new Blob([response.body], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, this.dataService.getFilename(response, 'Report.csv'));
    });
  }
}
