import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
  user: any;
  isMobileDevice = false;
  counter = new BehaviorSubject<number>(0);
  isAnyAdmin = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private router: Router) {
    const self = this;
    this.user = new BehaviorSubject(sessionStorage.getItem('currentUser') && JSON.parse(sessionStorage.getItem('currentUser')));
    this.isAnyAdmin.next(this.user.value && this.user.value.authorities.some(auth => ['PA', 'SA', 'UA'].includes(auth.authority)));
    // Anything less than 1200px would be considered as mobile/tablet
    var mql = window.matchMedia('(max-width: 1199px)');
    this.isMobileDevice = mql.matches;
  }

  login(username, password, content): Observable<any> {
    const userData = 'j_username=' + username + '&j_password=' + password;
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };
    return this.http.post('/j_spring_security_check', userData, config);
  }

  changePassword(user, requestBody): Observable<any> {
    return this.http.post(`/services/persons/changepassword/${user}`, requestBody);
  }

  logout() {
    this.http.post<any>('/logout', {}).subscribe();
    this.clearUser();
  }

  clearUser() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    this.user.next(null);
    this.router.navigate(['/login']);
  }
}
