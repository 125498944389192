export class UserDef {
  organizationID = null;
  personID = null;
  address1 = '';
  address2 = null;
  city = '';
  description = null;
  displayName = '';
  division = null;
  familyName = '';
  fcrd = null;
  givenName = '';
  isActive = true;
  jobRole = null;
  jobTitle = '';
  middleName = '';
  password = null;
  passwordExpiration = null;
  postalCode = null;
  state_Region = null;
  username = null;
  workEmail = null;
  workFax = null;
  workPhone = null;
  analystRating = null;
  sequence = null;
  roles = null
}

