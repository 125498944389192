import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertComponent} from '../_directives';

@Injectable()
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router, private modalService: NgbModal) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.modalService.dismissAll();
    this.subject.next({type: 'success', text: message});
    const modalRef = this.modalService.open(AlertComponent, {centered: true});
    modalRef.componentInstance.header = 'Success';
    modalRef.componentInstance.message = message;
  }

  error(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.modalService.dismissAll();
    this.subject.next({type: 'error', text: message});
    const modalRef = this.modalService.open(AlertComponent, { centered: true });
    modalRef.componentInstance.header = 'Error';
    modalRef.componentInstance.message = message;
  }

  message(header: string, message: string) {
    this.keepAfterNavigationChange = false;
    this.modalService.dismissAll();
    this.subject.next({type: 'message', text: message});
    const modalRef = this.modalService.open(AlertComponent, { centered: true });
    modalRef.componentInstance.header = header;
    modalRef.componentInstance.message = message;
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
