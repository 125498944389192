import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-cards-panel',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss']
})
export class CardComponent {
  @Input() adminPanels: any;
  @Output() downloadFileHandler = new EventEmitter();

  sendMessage(value) {
    this.downloadFileHandler.emit(value);
  }
}
