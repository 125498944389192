<!-- main app container -->
<div class="container-fluid">
  <div *ngIf="isLoggedIn" id="header">
    <div class="navbar navbar-expand-lg navbar-dark" id="headerDiv" ngbDropdown>
      <a class="navbar-brand" routerLink="/home">
        <img class="" id="imgheaderlogo" src="../assets/brand_logo.png" />
      </a>
      <a class="logout d-lg-none" (click)="logoutUser()">
        <i class="fa fa-power-off" aria-hidden="true"></i>
      </a>
      <button class="navbar-toggler  d-none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" ngbDropdownToggle>
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
        <div class="wm-logged-in" style="display: block;">
          <div class="wm-settings-wrapper">
            <div class="wm-drop-down-settings-component">
              <a class="wm-user-details-wrapper">
                <div class="wm-user-details">
                  <div class="wm-user-image-wrapper">
                    <img class="wm-user-image"
                      src="https://gravatar.com/avatar/212da39d64796fc3460c3d7cff0526a?d=blank" />
                    <div class="wm-user-fallback-image">&nbsp;</div>
                  </div>
                  <div class="user-name-actions">
                    <div class="user-name-wrapper">
                      <div class="user-name">{{user.displayName}}</div>
                    </div>
                  </div>
                  <span class="sub-menu-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="10" height="5.7"
                      viewBox="0 0 10 5.7" enable-background="new 0 0 9.992 5.695" xml:space="preserve">
                      <polygon fill="white" points="5 5.7 0 0.7 0.7 0 5 4.4 9.3 0 10 0.7 "></polygon>
                    </svg>
                  </span>
                </div>
              </a>
              <ul id="wm-settings-drop-down" class="narrow-header">
                <li>
                  <a (click)="showProfile(profile)">Profile</a>
                </li>
                <li>
                  <a (click)="showChangePassword(content)">Change Password</a>
                </li>

                <li>
                  <a (click)="logoutUser()">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="col-xs-12 col-md-12 col-sm-12 menuBar">
      <ul class="nav nav-justified">
        <a *ngFor="let nav of navItem" [routerLink]='nav.href' [class.disabled]="nav.disabled" class="nav-item">
          <i ngClass="{{nav.iconClass}}"></i>
          <span class="d-none d-sm-inline-block">{{nav.name}}</span>
        </a>
      </ul>
    </div>
  </div>
  <div id="body">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-fussion"></ngx-spinner>
    <app-notification></app-notification>
    <router-outlet></router-outlet>
  </div>
  <footer>
    <div class="" *ngIf="isLoggedIn">
      <div class="leftdiv">
        <p class="mb-0 pl-5 pt-3" style="color: white">
          <i class="fa fa-copyright"></i> Jovus {{currentYear}}</p>
      </div>
    </div>
  </footer>
</div>
<ng-template #content let-c="close()" let-d="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title">Change Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="d">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePasswordForm">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" class="form-control" formControlName="username" disabled>
      </div>
      <div class="form-group">
        <label for="oldPassword">Current Password:</label>
        <input type="password" id="oldPassword" class="form-control" formControlName="oldPassword">
      </div>
      <div class="form-group">
        <label for="newPassword">New Password:</label>
        <input type="password" id="newPassword" class="form-control" formControlName="newPassword">
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm Password:</label>
        <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
      </div>
      <div class="alert alert-danger"
        *ngIf="changePasswordForm.errors?.MatchPassword && (changePasswordForm.touched || changePasswordForm.dirty)">
        {{changePasswordForm.errors?.message}}
      </div>
      <div class="pull-right">
        <button type="button" class="btn btn-outline-dark pull-left" [disabled]="!changePasswordForm.valid"
          (click)="changePassword(); c">
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #profile let-c="close()" let-d="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile</h4>
    <button type="button" class="close" aria-label="Close" (click)="d">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body profile-modal">
    <div class="col">
      <ngb-tabset>
        <ngb-tab id="general" title="General">
          <ng-template ngbTabContent>
            <form name="form" id="generalForm" #documentForm="ngForm" class="pt-5 m-auto" disabled>
              <div class="form-group row">
                <div class="col-sm-2">
                  <label for="active" class="col-form-label">Active</label>
                  <input type="checkbox" class="align-text-bottom ml-3" id="active" name="active" [(ngModel)]="profileData.isActive" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label for="firstName" class="col-sm-2 col-form-label">First Name*</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="profileData.givenName" #firstName="ngModel" disabled>
                </div>
  
                <label for="mi" class="col-sm-2 col-form-label">Middle Name</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="mi" name="mi" [(ngModel)]="profileData.middleName" disabled>
                </div>
  
              </div>
  
              <div class="form-group row">
                <label for="lastName" class="col-sm-2 col-form-label">Last Name*</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="profileData.familyName" #lastName="ngModel" disabled>
                </div>
                <label for="jobTitle" class="col-sm-2 col-form-label">Title</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="jobTitle" name="jobTitle" [(ngModel)]="profileData.jobTitle" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label for="displayName" class="col-sm-2 col-form-label">Display Name*</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="displayName" name="displayName" [(ngModel)]="profileData.displayName" #displayName="ngModel" disabled>
                </div>
                <label for="loginName" class="col-sm-2 col-form-label">Login name*</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="loginName" name="loginName" [(ngModel)]="profileData.username" #loginName="ngModel" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label for="password" class="col-sm-2 col-form-label">Password*</label>
                <div class="col-sm-4">
                  <input type="password" class="form-control" id="password" name="password" [(ngModel)]="profileData.password" #password="ngModel" disabled>
                </div>
                <label for="primaryIndustry" class="col-sm-2 col-form-label">Sectors</label>
              <div class="col-sm-4">
                <ng-select name="primaryIndustry" id="primaryIndustry" [items]="industryDef"
                  [(ngModel)]="profileData.sector" #primaryIndustry="ngModel" [closeOnSelect]="false"
                  bindLabel="industryName" bindValue="industry" [multiple]="true" [hideSelected]="true" [readonly]="true">
                </ng-select>
              </div>
                <!-- <label for="sector" class="col-sm-2 col-form-label">Sectors</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="sector" name="sector" [(ngModel)]="profileData.address1" disabled>
                </div> -->
              </div>
              <div class="form-group row">
                <label for="city" class="col-sm-2 col-form-label">City</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="city" name="city" [(ngModel)]="profileData.city" disabled>
                </div>
                <label for="state" class="col-sm-2 col-form-label">State/Province</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="state" name="state" [(ngModel)]="profileData.state_Region" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label for="postal" class="col-sm-2 col-form-label">Zip/Postal code</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="postal" name="postal" [(ngModel)]="profileData.postalCode" disabled>
                </div>
                <label for="mailstop" class="col-sm-2 col-form-label">Mailstop</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="mailstop" name="mailstop" [(ngModel)]="profileData.division" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label for="phone" class="col-sm-2 col-form-label">Phone*</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="profileData.workPhone" #phone="ngModel"disabled>
                </div>
                <label for="fax" class="col-sm-2 col-form-label">Fax</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="fax" name="fax" [(ngModel)]="profileData.workFax" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label for="email" class="col-sm-2 col-form-label">Email*</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" id="email" name="email" [(ngModel)]="profileData.workEmail" #email="ngModel" disabled>
                </div>
                <label for="fcrd" class="col-sm-2 col-form-label">FCRD ID#</label>
                <div class="col-sm-4">
                  <input class="form-control" type="text" id="fcrd" name="fcrd" [(ngModel)]="profileData.fcrd" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label for="rating" class="col-sm-2 col-form-label">Starmine Rating</label>
                <div class="col-sm-4">
                  <input class="form-control" type="text" id="rating" name="rating" [(ngModel)]="profileData.analystRating" disabled>
                </div>
              </div>
            </form>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="permission" title="Permission">
          <ng-template ngbTabContent>
            <form name="form" id="permissionForm" #documentForm="ngForm" class="mt-5">
              <div class="container">
                <div class="row justify-content-end">
                  <input type="text" class="form-control w-25 mr-5" placeholder="Quick search" name="searchPermission" [(ngModel)]="searchPermission">
                </div>
                <div class="form-group row">
                  <div class="col-sm-3">
                    <label for="superAdmin" class="col-form-label">Super Admin</label>
                    <input type="checkbox" class="align-text-bottom ml-3" id="superAdmin" name="superAdmin" [(ngModel)]="rootPermission.sapermission" disabled>
                  </div>
                  <div class="col-sm-3">
                    <label for="passwordAdmin" class="col-form-label">Password Admin</label>
                    <input type="checkbox" class="align-text-bottom ml-3" id="passwordAdmin" name="passwordAdmin" [(ngModel)]="rootPermission.papermission" disabled>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-2">
                    <h5 class="pl-3">Authoring</h5>
                    <div class="row pt-2">
                      <div class="pl-3">
                        <span>C</span>
                      </div>
                      <div class="pl-4">
                        <span>E</span>
                      </div>
                      <div class="pl-4">
                        <span>V</span>
                      </div>
                      <div class="pl-4">
                        <span>A</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <h5 class="pl-2">Editing</h5>
                    <div class="row pt-2">
                      <div class="pl-3">
                        <span>E</span>
                      </div>
                      <div class="pl-4">
                        <span>V</span>
                      </div>
                      <div class="pl-4">
                        <span>A</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <h5 class="pl-0">Compliance</h5>
                    <div class="row pt-2">
                      <div class="pl-3">
                        <span>E</span>
                      </div>
                      <div class="pl-4">
                        <span>V</span>
                      </div>
                      <div class="pl-4">
                        <span>A</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <h5 class="pl-0">Production</h5>
                    <div class="row pt-2">
                      <div class="pl-3">
                        <span>E</span>
                      </div>
                      <div class="pl-4">
                        <span>V</span>
                      </div>
                      <div class="pl-4">
                        <span>A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-md-4">All Sectors</div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.Create" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.Edit" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.View" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Authoring.Approve" disabled/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Editing.Edit" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Editing.View" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Editing.Approve" disabled/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Compliance.Edit" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Compliance.View" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Compliance.Approve" disabled/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="row">
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Production.Edit" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Production.View" disabled/>
                      </div>
                      <div class="pl-3">
                        <input type="checkbox" [checked]="rootPermission.stagePermission.Production.Approve" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let sector of rootPermission.permission | permissionFilter: searchPermission">
                  <div class="row pt-3 sector-permission">
                    <div class="col-md-4">
                      <i class="fa" [ngClass]="sector.toggleIcon" (click)="onToggleExpand(sector)"></i>
                      <span>{{sector.sectorName}}</span>
                    </div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Authoring.Create" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Authoring.Edit" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Authoring.View" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Authoring.Approve" disabled/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Editing.Edit" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Editing.View" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Editing.Approve" disabled/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Compliance.Edit" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Compliance.View" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Compliance.Approve" disabled/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-center">
                      <div class="row">
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Production.Edit" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Production.View" disabled/>
                        </div>
                        <div class="pl-3">
                          <input type="checkbox" [checked]="sector.sectorPermission.Production.Approve" disabled/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="sector.issuerPermission && sector.expand">
                    <div class="row pt-3 issuer-permission" *ngFor="let issuer of sector.issuerPermission">
                      <div class="col-md-4 pl-md-5">{{issuer.issuerName}}</div>
                      <div class="col-md-2 text-center">
                        <div class="row">
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.Create" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.Edit" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.View" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Authoring.Approve" disabled/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 text-center">
                        <div class="row">
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Editing.Edit" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Editing.View" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Editing.Approve" disabled/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 text-center">
                        <div class="row">
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Compliance.Edit" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Compliance.View" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Compliance.Approve" disabled/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 text-center">
                        <div class="row">
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Production.Edit" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Production.View" disabled/>
                          </div>
                          <div class="pl-3">
                            <input type="checkbox" [checked]="issuer.issuerPermission.Production.Approve" disabled/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </form>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>

  </div>

</ng-template>