import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertService, AuthenticationService } from '../_services';
import { NotificationService } from '../_services/notification.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService, private authService: AuthenticationService, private notificationService: NotificationService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const skipLoader = request.headers.get('X-Skip-Loader') === 'true';

    if(skipLoader){
      request = request.clone({
        headers: request.headers.delete('X-Skip-Loader')
      });
    } else {
      this.authService.counter.next(this.authService.counter.getValue() + 1);
    }
    // append BASE_URL
    request = request.clone({
      url: `${environment.API_URL}${request.url}`
    });

    return next.handle(request).pipe(tap((response: HttpResponse<any>) => {
      if (response['body'] && response['body'].message) {
        this.notificationService.showSuccess(response['body'].message);
        // this.alertService.success(response['body'].message);
      }
      return response;
    }),
      catchError((err: HttpResponse<any>) => {
        let errorResponse;
        if (err['error'] instanceof ArrayBuffer) {
          const decodedString = String.fromCharCode.apply(null, new Uint8Array(err['error']));
          errorResponse = JSON.parse(decodedString);
        } else {
          errorResponse = err['error'];
        }
        const error = errorResponse.errorMessage || err.statusText;
        /*Logout when SESS_E_01*/
        if (errorResponse.exceptionCode === 'SESS_E_01') {
          this.authService.clearUser();
        }
        /*Don't show error modal when password change*/
        // AUTH_ERR_02 - Password Expired
        if (!['AUTH_ERR_02', 'PERSON_PASS_DU_13'].includes(errorResponse.exceptionCode)) {
          this.notificationService.showError(error);
          // this.alertService.error(error);
        }
        return throwError(errorResponse);
      }), finalize(() => {
        if (!skipLoader) {
          this.authService.counter.next(this.authService.counter.getValue() - 1);
        }
      }));
  }
}
