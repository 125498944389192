import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { unit } from '../components/documents/commonData';
import _ from 'lodash';

@Component({
  selector: 'app-document-unit-cell',
  styles: [
    `.select-editable {
      position: relative;
      background-color: white;
      width: 90px;
    }

    .select-editable select {
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 14px;
      border: none;
      width: 100px;
      margin: 0;
    }

    .select-editable input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 80px;
      padding: 1px;
      font-size: 12px;
      border: none;
    }

    .select-editable select:focus, .select-editable input:focus {
      outline: none;
    }`],
  template: `
    <div class="select-editable">
      <select [(ngModel)]="selectedValue" (change)="onUnitChange(this.selectedValue)" class="form-control">
        <option *ngFor="let unit of Units" [ngValue]="unit">{{unit}}</option>
      </select>
      <input type="text" class="form-control" name="format" [(ngModel)]="this.params.value"
             (change)="setValue(this.params)" [disabled]="true"/>
    </div>
  `
})
export class CustomUnitRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public selectedValue: string;
  public Units = _.keysIn(unit);
  public disableInput = true;
  agInit(params: any): void {
    const { colDef, data } = params;
    this.params = params;
    
    this.disableInput = !this.isCustomUnit(data[colDef.unitName]);
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  setValue(event): void {
    const { data, colDef, value } = event;
    data[colDef.field] = value;
    this.params.setValue(value);
  }

  isCustomUnit(input): boolean {
    const customPrefix = 'Custom';
    const pattern = new RegExp('^' + customPrefix);
    return pattern.test(input);
  }

  onUnitChange(selectedValue): void {
    const { data, colDef } = this.params;
    this.disableInput = !this.isCustomUnit(selectedValue);

    data[colDef.field] = this.params.value = unit[selectedValue];
    data[colDef.unitName] = selectedValue;
    this.params.setValue(selectedValue);
  }

}
