import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionRendererComponent, DocumentHeadlineRendererComponent, DocumentOpenRendererComponent } from 'src/app/_renderers';
import { ApiService, AuthenticationService, DataService } from '../../_services';
import { panels } from './commonData';

@Component({
  selector: 'app-documents',
  templateUrl: './all.documents.component.html',
  styleUrls: ['./all.documents.component.scss']
})
export class AllDocumentsComponent implements AfterViewInit, OnDestroy {
  documentList = ['Authoring', 'Editing', 'Compliance', 'Production', 'Recently Published'];
  cardQuickSearch: string;
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'State',
      field: 'isLocked',
      width: 60,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      cellRenderer: 'actionRenderer',
      sortable: true
      
    },
    {
      headerName: 'Document ID',
      field: 'documentID',
      width: 150,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      cellRenderer: 'documentOpenRenderer',
      sortable: true
    },
    {
      headerName: 'Document Type',
      field: 'documentType',
      width: 120,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Headline',
      field: 'headline',
      width: 140,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      cellRenderer: 'documentHeadlineRenderer',
      sortable: true
    },
    {
      headerName: 'Primary Analyst',
      field: 'primaryAnalyst',
      width: 140,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      cellRenderer: 'documentHeadlineRenderer',
      sortable: true
    },
    {
      headerName: 'Date Modified',
      field: 'lastModified',
      width: 110,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Last Modified',
      field: 'lastModifiedBy',
      width: 100,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 80,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Lock Owner',
      field: 'lockOwner',
      width: 100,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'View',
      field: 'make',
      width: 60,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      cellRenderer: 'actionRenderer',
      sortable: true
    },
    {
      headerName: 'Action',
      field: 'make',
      width: 70,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      cellRenderer: 'actionRenderer',
      sortable: true
    }
  ];

  rowHeight = 50;
  rowData: any;
  panelData: any;
  selectedTab: String;
  user: any;
  private gridApi;
  private gridColumnApi;
  frameworkComponents: any;
  paginationPageSize = 20;
  modalRef: any;
  stagename: any;

  constructor(private apiService: ApiService, private modalService: NgbModal, private dataService: DataService,
    private authService: AuthenticationService, private route: ActivatedRoute, private router: Router) {
    this.panelData = panels;
    this.user = this.authService.user.getValue();
    this.route.queryParams.subscribe(params => {
      this.dataService.selectedStage = params.stage || 'Authoring';
      this.selectedTab = dataService.selectedStage;
      this.stagename = dataService.selectedStage;
    });


    this.frameworkComponents = {
      documentHeadlineRenderer: DocumentHeadlineRendererComponent,
      documentOpenRenderer: DocumentOpenRendererComponent,
      actionRenderer: ActionRendererComponent
    };
  }

  ngAfterViewInit() {
    this.loadTableData(this.dataService.selectedStage);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.componentParent = this;
    params.api.sizeColumnsToFit();
  }

  setHeight() {
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 250;
    if ( window.innerHeight > 1000 ) {
      availableHeight = window.innerHeight - 600;
    }
    /*Get the HOT length*/
    const hotData = this.rowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * this.rowHeight) + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }
    this.gridStyle.height = `${availableHeight}px`;
  }

  loadTableData(stage) {
    this.router.navigate(['/documents'], { queryParams: { stage } });
    this.apiService.getDocuments(this.user.personID, this.user.username, stage).subscribe(({ data }) => {
      this.rowData = data[stage] || [];
      this.setHeight();
    });
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  openCreateDocumentModal(content) {
    this.modalRef = this.modalService.open(content, {
      centered: true,
      size: 'lg'
    });
  }

  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  // TODO

  onActionCellClicked(action, rowData, distributions): void {
    let defaultParams = {
      productid: rowData.productID,
      personid: this.user.personID,
      documentid: rowData.documentID,
      stageid: rowData.stageId
    };
    let params;
    if (action === 'delete') {
      params = { ...defaultParams };
      this.apiService.documentAction(action, params).subscribe(() => this.loadTableData(this.dataService.selectedStage));
    } else if (action === 'update') {
      params = { ...defaultParams, ...{ issuerid: rowData.issuerID } };
      this.apiService.documentAction(action, params).subscribe(() => this.loadTableData(this.dataService.selectedStage));
    } else if (action === 'reject') {
      params = { ...defaultParams, ...{ headline: rowData.headline } }
      this.apiService.sendEMail('sendemailonrejection', params).subscribe(() => {
        params = { ...defaultParams, ...{ currentstage: this.dataService.selectedStage, username: this.user.username } }
        this.apiService.documentAction(action, params).subscribe(() => this.loadTableData(this.dataService.selectedStage));
      });
    } else if (action === 'recall') {
      params = { ...defaultParams, ...{ currentstage: this.dataService.selectedStage, username: this.user.username } }
      this.apiService.documentAction(action, params).subscribe(() => this.loadTableData(this.dataService.selectedStage));
    } else if (action === 'resend') {
      params = { ...defaultParams, ...{ productDistributionRecipients: distributions } };
      this.apiService.resendDocument(params).subscribe();
    } else if (action === 'unlockdocument') {
      params = { ...defaultParams };
      this.apiService.documentAction(action, params).subscribe(() => this.loadTableData(this.dataService.selectedStage));
    }

  }
  getMobileCardParam(row, viewname, type = 'View') {
    return {
      isMobileView: true,
      colDef: { 'module': viewname, 'headerName': type },
      data: row,
      value: row.documentID,
      api: { componentParent: this }
    };
  }
}









