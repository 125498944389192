<div class="col mt-3">
  <div *ngIf="formModel.actionNote.documentID" class="head_id">
    <h4><b>Author Document: {{formModel.actionNote.documentID}}</b></h4>
  </div>
  <ngb-tabset (tabChange)="saveOnTabChange($event.nextId)" [activeId]="selectedTab" class="companyreport">
    <ngb-tab id="Metadata" title="Metadata">
      <ng-template ngbTabContent>
        <form #documentForm="ngForm" class="mt-3 mt-sm-2 row justify-content-center center-form" id="form" name="form">
          <div class="col-sm-8 col-md-10 col-lg-8 mdata  ">
            <div class="form-group row">
              <div *ngIf="formEnabled"
                class="form-group row justify-content-center col-sm-12 d-sm-block top-bt  d-lg-none">
                <div class="text-right bt">
                  <button (click)="onSave()" class="btn btn-primary" type="submit">
                    Save
                  </button>
                </div>
                <div class=" ml-3 text-right bt">
                  <button (click)="onClose()" class="btn btn-primary" type="submit">
                    Close document
                  </button>
                </div>
              </div>
              <label class="col-5 col-lg-3 col-md-3  text-lg-right col-form-label">Report Series*</label>
              <div class="col-7 col-lg-9 col-md-9">
                <div class="issuer">
                  <ng-select #reportSeries="ngModel" (change)="createDocument($event)"
                    [(ngModel)]="formModel.selectedPrimaryIssuer" class="dp" name="reportSeries"
                    placeholder="Select report series" required>
                    <ng-option *ngFor="let issuer of formModel.reportSeriesList" [value]="issuer.id">
                      {{issuer.value}}
                    </ng-option>
                  </ng-select>
                </div>
                <div [hidden]="reportSeries.valid || reportSeries.pristine" class="invalid-feedback">
                  Report Series is required
                </div>
              </div>
            </div>
            <fieldset *ngIf="formEnabled">
              <div class="form-group row">
                <label class="col-5 col-lg-3 col-md-3 text-lg-right  col-form-label">Headline*</label>
                <div class="col-7 col-lg-9 col-md-9">
                  <input #headline="ngModel" [(ngModel)]="formModel.actionNote.product.title" class="form-control"
                    name="headline" required type="text">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-lg-3 col-md-3 text-lg-right col-form-label">Subheadline*</label>
                <div class="col-7 col-lg-9 col-md-9">
                  <input #subheadline="ngModel" [(ngModel)]="formModel.actionNote.product.subTitle" class="form-control"
                    name="subheadline" required type="text">
                </div>
              </div>
              <div class="form-group row">
                <label class=" col-lg-3 col-md-3 col-sm-12 text-lg-right col-form-label">Primary Author(s)*</label>
                <div class=" col-lg-9 col-md-9 col-sm-12 ">
                  <ng-select #primaryAuthor="ngModel" [(ngModel)]="formModel.actionNote.primaryAuthors"
                    [closeOnSelect]="false" [hideSelected]="true" [items]="formModel.primaryAuthors" [multiple]="true"
                    bindLabel="displayName" name="primaryAuthor" placeholder="Select primary authors" required>
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-lg-3 text-lg-right col-form-label">Sectors*</label>
                <div class="col-sm-9 col-lg-9 col-form-label">
                  <ng-select #primaryIndustries="ngModel" [(ngModel)]="formModel.actionNote.primaryIndustries"
                    [closeOnSelect]="false" [hideSelected]="true" [items]="formModel.primaryIndustries"
                    [multiple]="true" bindLabel="industryName" name="primaryIndustries" placeholder="Select Sector"
                    required>
                  </ng-select>
                  <div [hidden]="primaryIndustries.valid || primaryIndustries.pristine" class="invalid-feedback">
                    Sector is required
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-lg-3 text-lg-right col-form-label">Primary Issuer(s)</label>
                <div class="col-sm-9 col-lg-9 col-form-label">
                  <ng-select #primaryIssuer="ngModel" [(ngModel)]="primaryIssuers" [closeOnSelect]="false"
                    [hideSelected]="true" [items]="formModel.primaryIssuers" [multiple]="true" bindLabel="issuerName"
                    name="primaryIssuer" placeholder="Select primary issuer">
                  </ng-select>
                </div>
              </div>

              <div class="form-group row" id="viewEditIssuers">
                <div class="col-sm-5"></div>
                <div class="col-sm-7">
                  <button class="btn btn-outline-primary edit-btn" (click)="editIssuerData(issuerModal);">View/Edit
                    Issuers
                    Details</button>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-lg-3 text-lg-right col-form-label">Secondary Issuer(s)</label>
                <div class="col-sm-9 col-lg-9 col-form-label">
                  <ng-select #secondaryIssuer="ngModel" [(ngModel)]="secIssuers" [closeOnSelect]="false"
                    [hideSelected]="true" [items]="formModel.primaryIssuers" [multiple]="true" bindLabel="issuerName"
                    name="secondaryIssuer" placeholder="Select secondary issuer">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-5 col-lg-3 col-md-3 text-lg-right col-form-label">Report Type*</label>
                <div class="col-7 col-lg-9 col-md-9 col-form-label">
                  <ng-select #reportType="ngModel" [(ngModel)]="formModel.actionNote.prodIndustries.reportTypeId"
                    name="reportType" required>
                    <ng-option *ngFor="let reportType of formModel.reportTypeList" [value]="reportType.id">
                      {{reportType.value}}
                    </ng-option>
                  </ng-select>
                  <div [hidden]="reportType.valid || reportType.pristine" class="invalid-feedback">
                    Report type is required
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div class="row ">
                    <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Available for Individual
                      Sale*</label>
                    <div class="col-7 col-lg-6 col-md-6">
                      <ng-select #availableForIndividualSale="ngModel"
                        [(ngModel)]="formModel.actionNote.product.individualSale" name="availableForIndividualSale"
                        required>
                        <ng-option [value]="'Yes'">Yes</ng-option>
                        <ng-option [value]="'No'">No</ng-option>
                      </ng-select>
                      <div [hidden]="availableForIndividualSale.valid || availableForIndividualSale.pristine"
                        class="invalid-feedback">
                        Availability selection is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6"
                  [hidden]="formModel.actionNote.product.individualSale !== 'Yes'">
                  <div class="row justify-content-start">
                    <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Single Report Price*</label>
                    <div class="col-7 col-lg-6 col-md-6 ">
                      <input #singleReportPrice="ngModel" [(ngModel)]="formModel.actionNote.product.singleReportPrice"
                        class="form-control" name="singleReportPrice" type="text">
                      <div [hidden]="formModel.priceValidator('singleReportPrice', singleReportPrice)"
                        class="invalid-feedback">
                        Single Report Price can only be number with two decimal
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-lg-3  text-lg-right col-form-label">Abstract</label>
                <div class="col-sm-9 col-lg-9">
                  <textarea [(ngModel)]="formModel.actionNote.product.abstract" class="form-control" name="abstract"
                    rows="4"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-lg-3 text-lg-right col-form-label">Industry Description</label>
                <div class="col-sm-9 col-lg-9">
                  <textarea [(ngModel)]="formModel.actionNote.prodIndustries.description" class="form-control"
                    name="description" rows="4"></textarea>
                </div>
              </div>
            </fieldset>
          </div>
          <div *ngIf="formEnabled" class="form-group row justify-content-center col-sm-12">
            <div class="text-right">
              <button (click)="onSave()" class="btn btn-primary" type="submit" [disabled]="formModel.actionNote.readOnly" >
                Save
              </button>
            </div>
            <div class="ml-3 text-right">
              <button (click)="onClose()" class="btn btn-primary" type="submit">
                Close document
              </button>
            </div>
          </div>
        </form>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="Comments" title="Comments" [disabled]="!formEnabled || formModel.actionNote.readOnly">
      <ng-template ngbTabContent>
        <div class="form-group pt-5 ">
          <div class="col-md-6 mt-4 row mx-auto">
            <div class="col-sm-2">
              <label class="control-label col-sm-3" for="seeComments">Comments</label>
            </div>

            <div class="col-sm-10">
              <pre class="form-control" id="seeComments" [innerHTML]="formModel.actionNote.product.comments" disabled
                style="opacity: 0.7; height: 200px"></pre>
            </div>
          </div>
          <div class="col-md-6 mt-4 row mx-auto">
            <div class="col-sm-2">
              <label class="control-label col-sm-3" for="addComments">Add
                Comments</label>
            </div>
            <div class="col-sm-10">
              <textarea class="form-control" id="addComments" [(ngModel)]='comments' rows="5"></textarea>
            </div>
          </div>
          <div class="col-md-6 mt-3 row mx-auto">
            
            <div class="col-sm-12 text-center">
              <button type="submit" class="btn btn-primary mr-2" (click)="onAddComment()">Add Comments
              </button>
              <button type="submit" class="btn btn-primary mr-2" (click)="onClose()">Close Document
              </button>
            </div>

          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="AuthorDocument" title="Author Document" [disabled]="!formEnabled || formModel.actionNote.readOnly">
      <ng-template ngbTabContent>
        <div class="form-group d-flex justify-content-center mt-5">
          <fieldset class="border border-dark p-4 col-5">
            <legend class="w-auto">Download Document</legend>
            <div class="d-flex justify-content-around">
              <button class="btn btn-outline-primary" (click)="getBlankTemplate()">Download blank template</button>
              <button class="btn btn-outline-primary" (click)="checkOutDocument()">Check out existing document </button>
            </div>
          </fieldset>
        </div>
        <div class="form-group d-flex justify-content-center mt-5">
          <fieldset class="border border-dark p-4 col-5">
            <legend class="w-auto">Check in document</legend>
            <div class="input-group mb-3">
              <div class="custom-file mr-5">
                <input #fileInput (change)="onFileChange($event, fileInput)" class="custom-file-input"
                  id="inputGroupFile02" type="file">
                <label class="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">
                  {{checkInDocumentPath}}
                </label>
              </div>
              <div class="input-group-append">
                <button (click)="checkIn()" [disabled]="!checkInDocument" class="input-group-text btn btn-primary"
                  id="inputGroupFileAddon02">
                  Upload
                </button>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="form-group d-flex justify-content-center mt-5">
          <button type="submit" class="btn btn-primary mr-2" (click)="onClose()">Close Document</button>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab id="DistributionRecipients" title="Distribution Recipients" *ngIf="!enableTab('distribution')">
      <ng-template ngbTabContent>
        <div class="form-group d-flex justify-content-end mt-5">
          <button type="submit" class="btn btn-primary mr-2" (click)="saveDistribution()">Save</button>
          <button type="submit" class="btn btn-primary mr-2" (click)="onClose()">Close Document</button>
        </div>
        <div class="row">
          <div class="form-group ml-5 col-2">
            <div class="form-check mb-2" *ngFor="let recipient of formModel.actionNote.recipientList">
              <input class="form-check-input" type="checkbox" [(ngModel)]="recipient.selected"
                [id]="recipient.channelID" [disabled]="!recipient.editable">
              <label class="form-check-label" [for]="recipient.channelID">
                {{recipient.channelDescription}}
              </label>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>

  </ngb-tabset>
</div>
<ng-template #issuerModal let-c="close()" let-d="dismiss()" class="mt-5">
  <form #documentForm="ngForm" id="issuerForm" name="issuerForm">
    <div class="modal-header">
      <span>View Edit Issuers</span>
    </div>
    <div class="modal-body" style="min-height:250px; overflow-y:scroll;">
      <div class="container pl-0 pr-0 ">
        <div id="accordion">
          <div *ngFor="let issuer of industryIssuers; index as $index" class="d-lg-none d-sm-block">
            <div class="card card-3 ">
              <div class="card-header text-center text-white bg-blue p-0  ">
                <p class="mb-0 ed">
                  <a href="#collapseFive{{$index}}" data-toggle="collapse"
                    data-parent="#accordion">{{ issuer.issuer.issuerName }}</a>
                </p>
              </div>
              <div class="card-body panel-collapse collapse in   " id="collapseFive{{$index}}" data-parent="#accordion">
                <div class="card_sec">
                  <div class="row">
                    <div class="col-6">
                      <h4 class="card-title mt-0"> Recommendation: </h4>
                      <ng-select name="recommendation{{$index}}" [items]="formModel.industryRecommendations"
                        [(ngModel)]="issuer.recommendation" [appendTo]="'body'">
                      </ng-select>
                    </div>

                    <div class="col-6">
                      <h4 class="card-title mt-0"> Currency: </h4>
                      <ng-select name="currency{{$index}}" [items]="formModel.currencies"
                        [(ngModel)]="issuer.defaultCurrency" [appendTo]="'body'">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="card_sec">
                  <div class="row">
                    <div class="col-6">
                      <h4 class="card-title"> Fair Value: </h4>
                      <input type="text" class="form-control" [(ngModel)]="issuer.targetPrice"
                        name="targetPrice{{$index}}" #targetPrice="ngModel" />
                      <div [hidden]="formModel.priceValidator('fairPrice', targetPrice)" class="invalid-feedback">
                        Fair value can only be number with two decimal
                      </div>
                    </div>
                    <div class="col-6">
                      <h4 class="card-title"> Prior Fair Value: </h4>
                      <input type="text" class="form-control" [(ngModel)]="issuer.previousTargetPrice"
                        name="previousTargetPrice{{$index}}" #previousTargetPrice="ngModel" />
                      <div [hidden]="formModel.priceValidator('currentPrice', previousTargetPrice)"
                        class="invalid-feedback">
                        Prior fair value can only be number with two decimal
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="">
          <table class="table table-responsive d-none d-lg-block" id="table-issuers">
            <thead>
              <tr>
                <th style="width:25%">Issuer Name</th>
                <th style="width:25%">Current Recommendation</th>
                <th style="width:15%">Default Currency</th>
                <th style="width:17%">Fair Value</th>
                <th style="width:17%">Prior Fair Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let issuer of industryIssuers; index as $index">
                <td style="width:25%" class="form-group">
                  <p>{{ issuer.issuer.issuerName }}</p>
                </td>
                <td style="width:25%" class="form-group">
                  <ng-select name="recommendation{{$index}}" [items]="formModel.industryRecommendations"
                    [(ngModel)]="issuer.recommendation" [appendTo]="'body'">
                  </ng-select>
                </td>
                <td style="width:15%" class="form-group">
                  <ng-select name="currency{{$index}}" [items]="formModel.currencies"
                    [(ngModel)]="issuer.defaultCurrency" [appendTo]="'body'">
                  </ng-select>
                </td>
                <td style="width:17%" class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="issuer.targetPrice" name="targetPrice{{$index}}"
                    #targetPrice="ngModel" />
                  <div [hidden]="formModel.priceValidator('fairPrice', targetPrice)" class="invalid-feedback">
                    Fair value can only be number with two decimal
                  </div>
                </td>
                <td style="width:17%" class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="issuer.previousTargetPrice"
                    name="previousTargetPrice{{$index}}" #previousTargetPrice="ngModel" />
                  <div [hidden]="formModel.priceValidator('currentPrice', previousTargetPrice)"
                    class="invalid-feedback">
                    Prior fair value can only be number with two decimal
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" (click)="d">Close</button>
          <button type="button" class="btn btn-primary pull-right" data-dismiss="modal" (click)="c"
            [disabled]="!documentForm.form.valid">Save Issuer Details</button>
        </div>
      </div>
    </div>
  </form>
</ng-template>