import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../_services';
import { Router } from '@angular/router';
import { UserDef } from './UserDef';
import _ from 'lodash';

@Component({
  templateUrl: 'user.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class UserAdminComponent implements OnInit {
  PERMISSION_LEVEL = {
    ROOT: 'root',
    SECTOR: 'sector',
    ISSUER: 'issuer'
  };
  private gridApi;
  rootPermission: any;
  searchPermission = '';
  createView = false;
  selectedRow: any;
  userDef: any;
  rowHeight = 40;
  paginationPageSize = 20;
  rowSelection = 'single';
  selectedTab = 'general';
  actionLabel = 'Update';
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'UserID',
      field: 'personID',
      width: 260,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Display Name',
      field: 'displayName',
      width: 260,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Phone number',
      field: 'workPhone',
      width: 220,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Job title',
      field: 'jobTitle',
      width: 240,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Email',
      field: 'workEmail',
      width: 380,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }];

  fetchUserPermissions() {
    this.adminService
      .getUserPermissions(this.selectedRow.personID)
      .subscribe(({ data }) => {
        this.rootPermission = data;
        this.rootPermission.permission = this.rootPermission.permission.map(sector => {
          sector.expand = true;
          sector.toggleIcon = 'fa-minus-circle';
          return sector;
        }
        );
      });
  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  onSelectionChanged() {
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
    this.fetchUserPermissions();
  }

  onToggleExpand(sector: any) {
    sector.expand = !sector.expand;
    sector.toggleIcon = sector.toggleIcon === 'fa-plus-circle' ? 'fa-minus-circle' : 'fa-plus-circle';
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  /**
   * This method will handle changes made to permissions
   * @param permissionObject - Permission object which is changed
   * @param value - The permission which is changed
   * @param level - Level of change of permission (root, sector or issuer)
   * @param parentSector - Needed in case when issuer permission is changed, so as to know the parent sector
   */
  onPermissionChange(permissionObject, stage, value, level, parentSector = null) {
    if (level === this.PERMISSION_LEVEL.ROOT) {
      const { stagePermission } = permissionObject;
      stagePermission[stage][value] = !stagePermission[stage][value];
      const { permission } = this.rootPermission;
      permission.forEach(sector => {
        sector.sectorPermission[stage][value] = stagePermission[stage][value];
        const { issuerPermission } = sector;
        if (issuerPermission) {
          issuerPermission.forEach(element => {
            element.issuerPermission[stage][value] = stagePermission[stage][value]
          });
        }
      });
    } else if (level === this.PERMISSION_LEVEL.SECTOR) {
      const { issuerPermission } = permissionObject;
      const { sectorPermission } = permissionObject;
      sectorPermission[stage][value] = !sectorPermission[stage][value];
      if (issuerPermission) {
        issuerPermission.forEach(issuer => issuer.issuerPermission[stage][value] = sectorPermission[stage][value]);
      }
      this.rootPermission.stagePermission[stage][value] = this.rootPermission.permission
        .every(sector => !!sector.sectorPermission[stage][value]);

    } else {
      const { issuerPermission } = permissionObject;
      issuerPermission[stage][value] = !issuerPermission[stage][value];
      parentSector.sectorPermission[stage][value] = parentSector.issuerPermission.every(issuer => !!issuer.issuerPermission[stage][value]);
      this.rootPermission.stagePermission[stage][value] = this.rootPermission.permission
        .every(sector => !!sector.sectorPermission[stage][value]);
    }
  }

  saveOnTabChange(tab) {
    if (this.selectedTab === 'general') {
      this.onSaveUserDetails();
    } else {
      this.onUpdateUserPermission();
    }
    this.selectedTab = tab;
  }

  onSaveUserDetails() {
    this.adminService.saveUserGeneralDetails(this.selectedRow.personID ? 'updateperson' : 'createperson', this.selectedRow).subscribe(({ data: { person } }) => {
      if (this.createView) {
        this.selectedRow.personID = person.personID;
        this.fetchUserPermissions();
      } else {
        const rowNode = this.gridApi.getRowNode(person.personID);
        rowNode.setData(this.selectedRow);
      }
    });
  }

  onUpdateUserPermission() {
    const sectorPermission = this.rootPermission.permission.map(sector => ({ ...sector }));
    this.rootPermission.permission = this.rootPermission.permission.map(sector => {
      delete sector.expand;
      delete sector.toggleIcon;
      return sector;
    });
    this.adminService
      .updateUserPermission(this.rootPermission, this.selectedRow.personID)
      .subscribe(() => {
        this.rootPermission.sectorPermission = sectorPermission;
        this.fetchUserPermissions();
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();
    this.resetHeight();
  }

  getRowNodeId({ personID }) {
    return personID;
  }

  constructor(
    private adminService: AdminService,
    private router: Router
  ) {

  }

  resetHeight() {
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    if ( window.innerHeight > 1000 ) {
      availableHeight = window.innerHeight - 600;
    }
    /*Get the HOT length*/
    const hotData = this.userDef || [];
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * this.rowHeight) + 50;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }
    this.gridStyle.height = `${availableHeight}px`;
  }

  ngOnInit() {
    this.createView = this.router.url === '/users/newUser';
    if (this.createView) {
      this.selectedRow = new UserDef();
      this.actionLabel = 'Save';
    } else {
      this.adminService.getAllPersons().subscribe(({ data }) => {
        this.userDef = data;
        this.resetHeight();
      })
    }
  }

  emailValidator(email) {
    return /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(email.control.value);
  }
}
