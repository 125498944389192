export class TickerDef {
  isActive = false;
  country = 'US';
  exchangeID = null;
  isPrimary = false;
  symbolSet = 'S&P';
  ticker = '';
  securityID = null;

  constructor(data = null, defaultExchange = null) {
    this.exchangeID = defaultExchange;
    if (data) {
      this.isActive = data.isActive;
      this.country = data.country;
      this.exchangeID = data.exchange.exchangeID;
      this.isPrimary = data.isPrimary;
      this.symbolSet = data.symbolSet;
      this.ticker = data.ticker;
      this.securityID = data.securityID;
    }
    
  }
}

export class IssuerDef {
      issuerID = null
      companyDescription= null;
      country= null;
      creditRating= null;
      description= null;
      directorship= null;
      dividendRanking= null;
      fiscalUsesCalendar= false;
      fiscalYearEnd= null;
      isActive= true;
      issuerIndex= null;
      issuerName= null;
      issuerType= null;
      legalName= null;
      localName= null;
      logoURL= null;
      parentName= null;
      peerGroup= null;
      priceChartId= null;
      priceChartURL= null;
      region= null;
      relatedDisclosures= null;
      sector= null;
      tradingName= null;
      primaryIndustry= null;
      primaryIndustryId= null;
      reutersIndustry= null;
      bbergIndustry= null;
      fcrdIndustry= null;
      securityName= null;
      securityId= null;
      cusip = null;
}
