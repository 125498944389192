import { Component, OnInit } from '@angular/core';
import { AdminService, ApiService } from '../../../_services';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { IssuerDef, TickerDef } from './IssuerDef';
import _ from 'lodash';

@Component({
  templateUrl: 'issuer.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class IssuerAdminComponent implements OnInit {
  private gridApi;
  symbolSet = ['S&P', 'Bloomberg', 'Reuters'];
  createView = false;
  countryNames = ['United States', 'Brazil', 'Australia', 'Canada', 'United Kingdom', 'Japan', 'Germany', 'China'];
  exchangeCountries = [];
  defaultExchangeID = null;
  issuerDef: any;
  tickerDef = [];
  industryDef = [];
  userDef = [];
  exchangeChoice = [];
  rowHeight = 40;
  paginationPageSize = 20;
  rowSelection = 'single';
  selectedRow: any;
  actionLabel = 'Update';
  monthsList = moment.months();
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'IssuerID',
      field: 'issuerID', 
      width: 260,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Display Name',
      field: 'issuerName',
      width: 260,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Price Chart Identifier',
      field: 'priceChartId',
      width: 200,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Primary Industry',
      field: 'primaryIndustry',
      width: 300,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Country',
      field: 'country',
      width: 340,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }];

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  getRowNodeId({ issuerID }) {
    return issuerID;
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  onPrimaryChange(ticker) {
    this.tickerDef.forEach(tkr => {
      tkr.isPrimary = false;
    });
    ticker.isPrimary = true;
  }

  onSelectionChanged() {
    const selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
    this.adminService.getIssuer(selectedRow.issuerID).subscribe(({ data }) => {
      this.selectedRow = data;
    })
    this.adminService.getIssuerExhange(selectedRow.issuerID).subscribe(({ data }) => {
      this.tickerDef = data.map(row => new TickerDef(row));
      while (this.tickerDef.length < 2) {
        this.tickerDef.push(new TickerDef());
      }
    })

  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  onSaveIssuer() {
    const issuer = _.cloneDeep(this.selectedRow);
    const issuerExchanges = _.cloneDeep(this.tickerDef).filter(row => !!row.ticker);

    this.adminService.saveIssuer(this.createView ? 'createissuer' : 'updateissuer', {issuer, issuerExchanges}).subscribe(({data}) => {
      if (this.createView) {
        this.selectedRow.issuerID = data.issuerID;
      } else {
        const rowNode = this.gridApi.getRowNode(data.issuerID);
        rowNode.setData(this.selectedRow);
      }
    });
  }

  constructor(
    private adminService: AdminService,
    private apiService: ApiService,
    private router: Router
  ) {
  }


  resetHeight() {
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    if ( window.innerHeight > 1000 ) {
      availableHeight = window.innerHeight - 600;
    }
    /*Get the HOT length*/
    const hotData = this.issuerDef || [];
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * this.rowHeight) + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }


  ngOnInit() {
    this.createView = this.router.url === '/issuers/newIssuer';
    this.adminService.getAllExchanges().subscribe(({ data }) => {
      this.exchangeChoice = data;
      data.forEach((row) => {
        if (!this.exchangeCountries.includes(row.countryCode)) {
          this.exchangeCountries.push(row.countryCode);
        }
        if (row.tradingExchange === 'AMEX') {
          this.defaultExchangeID = row.exchangeID;
        }
      });
      if (this.createView) {
        this.selectedRow = new IssuerDef();
        while (this.tickerDef.length < 2) {
          this.tickerDef.push(new TickerDef(null, this.defaultExchangeID));
        }
        this.actionLabel = 'Save';
      }
    });
    this.apiService.getAllIndustries().subscribe(({ data }) => {
      this.industryDef = data;
    })
    if (!this.createView) {
      this.adminService.getAllIssuers().subscribe(({ data }) => {
        this.issuerDef = data;
        this.resetHeight();
      });
    }
  }

}
