import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  user: any;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) {
    this.authService.user.subscribe((value) => (this.user = value));
  }
  //Persons API
  getProfile():Observable<any> {
    return this.http.get(`/services/persons/person/${this.user.personID}`);
  }
  getDocuments(userID, userName, stage): Observable<any> {
    return this.http.get(`/services/persons/documents/${userID}/${userName}/${stage}`);
  }
  getPrimaryIssuers(personID): Observable<any> {
    return this.http.get(`/services/persons/primaryissuers/${personID}`);
  }
  getPrimaryIndusties(personID): Observable<any> {
    return this.http.get(`/services/persons/primaryindustries/${personID}`);
  }
  //Admin API
  getReportTypeSeries(type, active): Observable<any> {
    return this.http.get(`/services/admin/${type}/list?type=${active ? 'active' : 'all'}`);
  }
  //Industry API
  getAllIndustries(): Observable<any> {
    return this.http.get(`/services/industry/allindustries`);
  }
  //Document API
  downloadDocument(type, docName): Observable<any> {
    return this.http.get(`/services/documents/download/${type}?documentid=${docName}`, { responseType: 'arraybuffer', observe: 'response' })
  }
  uploadDocument(docName, formData): Observable<any> {
    return this.http.post(`/services/documents/upload/${docName}`, formData);
  }
  downloadPDF(documentID): Observable<any> {
    return this.http.get(`/services/documents/downloadpdf/${documentID}`, { responseType: 'arraybuffer', observe: 'response' })
  }
  checkPDF(documentID): Observable<any> {
    return this.http.get(`/services/documents/checkpdf/${documentID}`);
  }
  //Document Stages API
  documentAction(type, params): Observable<any> {
    return this.http.get(`/services/documentstages/${type}`, { params })
  }
  resendDocument(requestBody): Observable<any> {
    return this.http.post(`/services/documentstages/resendDocument`, requestBody);
  }
  //Action Notes API
  openDocument(type, params): Observable<any> {
    return this.http.get(`/services/actionnotes/open/${type}`, { params })
  }
  createDocument(type, params): Observable<any> {
    return this.http.get(`/services/actionnotes/create/${type}`, { params });
  }
  updateDocument(type, requestBody): Observable<any> {
    return this.http.put(`/services/actionnotes/update/${type}?personid=${this.user.personID}`, requestBody);
  }
  checkMandatoryMetaData(type, params): Observable<any> {
    return this.http.get(`/services/actionnotes/metainformation/${type}`, { params });
  }
  //Email API
  sendEMail(type, params): Observable<any> {
    return this.http.post(`/services/email/${type}`, params);
  }
  //Distribution API
  getDistributionRecipients(param): Observable<any> {
    return this.http.get(`/services/distribution/productDistributionRecipients/${param}`);
  }
  //Product API
  fetchIssuerDetails(prodId): Observable<any> {
    return this.http.get(`/services/products/prodissuer/fetch?productid=${prodId}`);
  }
  updateIssuerDetails(prodId, requestBody): Observable<any> {
    return this.http.put(`/services/products/prodissuer/update?productid=${prodId}`, requestBody);
  }
  
  downloadAddInInstaller(type): Observable<any> {
    return this.http.get(`/services/documents/download/installer?installerType=${type}`, { responseType: 'arraybuffer', observe: 'response' });
  }

  downloadBlankWorkbook(): Observable<any> {
    return this.http.get(`/services/documents//download/blankexcel`,
      { responseType: 'arraybuffer', observe: 'response' });
  }

  // uploadFinancialModel(data, productID, personID, currency ): Observable<any>{
  //   return this.http.post(`/services/financials/upload/excelfinancialmatrix/productid/${productID}/personid/${personID}/currency/${currency}`,data);
  // }

  uploadFinancialModel(data: FormData): Observable<any> {
    return this.http.post('/services/financials/upload/excelfinancialmatrix', data);
  }
  

  clearFinancialMatrix(action, payload){
    return this.http.post(`/services/actionnotes/clear/companyreport/${action}` ,payload);
  }

  //Admin API
  getAllIssuers(): Observable<any> {
    return this.http.get(`/services/issuers/allissuer`);
  }

  importFinancialMatrix(requestBody, options?:any): Observable<any> {
    return this.http.post('/services/financials/import/financialmatrix', requestBody, options);
  }

  revertFinancialMatrixPreviousUpload(issuerId): Observable<any> {
    let params = new HttpParams()
      .set('issuerID', issuerId);
    return this.http.get('/services/financials/lastpublishedfinancialmatrix',{params});
  }

  getProductEarningSummaryCategory(productId, issuerId){
    let params = new HttpParams()
      .set('productID', productId)
      .set('issuerID', issuerId);
    return this.http.get('/services/financials/getearningsummerycategory', {params});
  }

  getDefaultEarningSummaryCategory(){
    return this.http.get('/services/financials/getearningsummerycategory');
  }

  setEarningSummaryCategory(productId,payload){
    let params = new HttpParams()
    .set('productId', productId);
    return this.http.post('/services/financials/earningsummerycategory',payload);
  }
  
  
}
