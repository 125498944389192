import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../_services';
import {Subscription} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  user: any;
  userSubscription: Subscription;

  constructor(private router: Router, private authService: AuthenticationService) {
    this.userSubscription = this.authService.user.subscribe((user) => this.user = user);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user) {
      const isAdmin = this.authService.isAnyAdmin.value;
      const regex = new RegExp(/documents|companyreport\/open|industryreport\/open/g)
      if (this.authService.isMobileDevice && !regex.test(state.url)) {
        this.router.navigate(['/documents']);
        return false;
      } else if (['/admin', '/reports'].includes(state.url) && !isAdmin) {
        this.router.navigate(['/home']);
        return false;
      }
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
