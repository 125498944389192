import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { PasswordValidation } from '../_helpers';
import { AuthenticationService, ApiService, AdminService } from '../_services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  userSubscription: Subscription;
  isLoggedIn: boolean;
  currentYear: Number;
  user: any;
  profileData: any;
  rootPermission: any;
  changePasswordForm: FormGroup;
  content: any;
  industryDef: any= [];
  searchPermission = '';
  prevLoaderValue = 0;
  navItem = [
    {
      name: 'Home',
      disabled: false,
      href: '/home',
      iconClass: 'fa fa-home mr-2 mt-2'
    },
    {
      name: 'Document',
      disabled: false,
      href: '/documents',
      iconClass: 'fa fa-plus-square-o mr-2 mt-2'
    },
    {
      name: 'Administration',
      disabled: true,
      href: '/admin',
      iconClass: 'fa fa-user-plus mr-2 mt-2'
    },
    {
      name: 'Reports',
      disabled: true,
      href: '/reports',
      iconClass: 'fa fa-book mr-2 mt-2'
    }
  ];

  constructor(private authService: AuthenticationService,
    private modalService: NgbModal, private spinner: NgxSpinnerService, private apiService: ApiService, private adminService: AdminService) {
    this.changePasswordForm = new FormGroup({
      username: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    }, {
      validators: PasswordValidation
    });

    this.authService.counter.subscribe(value => {
      if (value > 0) {
        this.spinner.show();
        this.prevLoaderValue = value;
      } else if (this.prevLoaderValue > 0) {
        this.prevLoaderValue = value;
        this.spinner.hide();
      }
    });
  }

  showChangePassword(content): void {
    this.content = content;
    this.changePasswordForm.setValue({
      username: this.authService.user.value.username,
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    this.changePasswordForm.setErrors({ MatchPassword: false });
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  onToggleExpand(sector: any) {
    sector.expand = !sector.expand;
    sector.toggleIcon = sector.toggleIcon === 'fa-plus-circle' ? 'fa-minus-circle' : 'fa-plus-circle';
  }

  showProfile(profile): void {
    this.searchPermission = '';
    this.apiService.getAllIndustries().subscribe(({ data }) => {
      this.industryDef = data;
    })
    this.apiService.getProfile().subscribe(({ data }) => {
      this.profileData = data.basic_information;
      this.profileData.active = !!this.profileData.isActive;
      this.rootPermission = data.permission_information;
      this.rootPermission.sectorPermission = this.rootPermission.permission.map(sector => {
        sector.expand = false;
        sector.toggleIcon = 'fa-plus-circle';
        return sector;
      }
      );
      this.modalService.open(profile, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        windowClass: 'profile-modal',
        container: 'app-root'
      });
    });

  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.isLoggedIn = !!user;
      this.user = user;
      if (this.isLoggedIn) {
        this.authService.isAnyAdmin.subscribe(auth => {
          this.navItem.forEach((value) => {
            if (!['Home', 'Document'].includes(value.name)) {
              value.disabled = !auth;
            }
          });
        });
      }
    });
    
    this.currentYear = new Date().getFullYear();
  }

  changePassword(): void {
    const requestBody = {
      personID: '',
      creation: null,
      oldPassword: this.changePasswordForm.get('oldPassword').value,
      password: this.changePasswordForm.get('newPassword').value,
      iscurrent: null
    };
    this.authService.changePassword(this.user.username, requestBody).subscribe(data => { }, error => {
      this.changePasswordForm.setValue({
        username: this.authService.user.getValue().username,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      if (error.exceptionCode === 'PERSON_PASS_DU_13') {
        this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title' });
        this.changePasswordForm.setErrors({ MatchPassword: true, message: error.errorMessage });
      }
    });
  }

  logoutUser(): void {
    this.authService.logout();
  }
}
