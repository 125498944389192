import {Component, OnInit} from '@angular/core';
import {AdminPanel} from '../../common/AdminPanel';
import {panels} from '../../common/panelData';

@Component({
  templateUrl: 'admin.component.html',
  styleUrls: ['admin.component.scss']
})
export class AdminComponent implements OnInit {
  adminPanels = [];

  constructor() {
  }

  addAdminPanel() {
    // panels.filter(panel => !['Company Documents', 'Industry Documents', 'filler'].includes(panel.name)).forEach(panel => {
    panels.filter(panel => ['User', 'Issuer', 'Distributors', 'Industries', 'Report Series', 'Report Type', 'Ratings Distribution','Estimates and Valuations', 'filler'].includes(panel.name)).forEach(panel => {
      this.adminPanels.push(
        new AdminPanel(
          panel.icon,
          panel.iconUrl,
          panel.name,
          panel.description,
          panel.options
        )
      );
    });
  }

  ngOnInit() {
    this.addAdminPanel();
  }
}
