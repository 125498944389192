<div class="modal-header">
  <h4 class="modal-title">{{header}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
</div>

