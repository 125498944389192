<div class="container mt-5 ratingsDistribution">
    <p class="title">Zelman & Associates Distribution of Investment Rating</p>
    <form class="form-inline">
      <div class="form-group">
        <div class="input-group">
          <label class="text-right col-form-label mr-2">As of </label>
          <input #d="ngbDatepicker" [(ngModel)]="date" class="form-control" name="dp" ngbDatepicker
          placeholder="yyyy-mm-dd" >
          <div class="input-group-append">
            <button (click)="d.toggle()" class="btn btn-outline-secondary calendar fa fa-calendar" type="button"></button>
          </div>
        </div>
      </div>
    </form>
    <div [ngStyle]="gridStyle">
      <ag-grid-angular (gridReady)="onGridReady($event)" [columnDefs]="columnDefs" [rowData]="rowData" class="ratings-ditribution-table ag-theme-balham pt-3"
                       [gridOptions]="gridOptions" style="clear: both; width: 100%; height: 100%">
      </ag-grid-angular>
    </div>
    <div class="mt-5">
      <button (click)="updateRatings()" class="btn btn-primary">Update</button>
    </div>
  </div>
  
  