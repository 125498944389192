export * from './404/404.component';
export * from './login/login.component';
export * from './app.component';
export * from './home/home.component';
export * from './documents/all.documents.component';
export * from './documents/companyreport.component';
export * from './admin/admin.component';
export * from './admin/user/user.admin.component';
export * from './documents/industryreport.component';
export * from './admin/issuer/isssuer.admin.component';
export * from './admin/distributor/distributor.admin.component';
export * from './admin/industry/industry.admin.component';
export * from './admin/report/report.admin.component';
export * from './reports/reports.component';
export * from './admin/distributor/ratings.distribution.component';
