import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
  private _openDocumentData: any;
  private _openMediaData: any;
  private _selectedStage: string;

  get selectedStage(): string {
    return this._selectedStage;
  }

  set selectedStage(value: string) {
    this._selectedStage = value;
  }

  get openDocumentData(): any {
    return this._openDocumentData;
  }

  set openDocumentData(value: any) {
    this._openDocumentData = value;
  }

  get openMediaData(): any {
    return this._openMediaData;
  }

  set openMediaData(value: any) {
    this._openMediaData = value;
  }

  constructor() {
  }

  getFilename(response: any, filename: string) {
    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }
}
