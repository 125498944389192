<div class="container">
  <div class="row justify-content-center">
    <div *ngFor='let adminPanel of adminPanels' [ngStyle]="{'visibility': adminPanel.name === 'filler' ? 'hidden' : 'visible'}"
         class="col-md-5 border rounded mb-4  pt-2 iconblock">
      <div class="row">

        <div class="col-9">
          <h4 class="reportText">{{adminPanel.name}}</h4>
          <p>{{adminPanel.description}}
          </p>
          <div class="row">
            <p *ngFor='let option of adminPanel.options' class="col-6 pr-0">
              <a (click)="sendMessage(option)" *ngIf="option.enabled" [routerLink]="[option.optionUrl]" class="adminLink">
                <b>{{option.optionName}}</b>
              </a>
              <a *ngIf="!option.enabled" class="disabled" href="#">
                <b>{{option.optionName}}</b>
              </a>
            </p>
          </div>
        </div>


        <div class="col-3">
          <a [routerLink]="[adminPanel.iconUrl]">
            <i [ngClass]="[adminPanel.icon, 'fa fa-2x adminIcon']"></i>
          </a>
        </div>

      </div>
    </div>
  </div>
</div>


