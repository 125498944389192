import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgGridModule } from 'ag-grid-angular'
import { NgbTabsetModule, NgbPopoverModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { DragDropModule } from '@angular/cdk/drag-drop';



import { AlertComponent, CardComponent } from './_directives';
import { AuthGuard, JwtInterceptor } from './_helpers';
import { AlertService, AuthenticationService, DataService, AdminService } from './_services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, LoginComponent, HomeComponent, AllDocumentsComponent, CompanyreportComponent, AdminComponent, UserAdminComponent, 
  IndustryreportComponent, IssuerAdminComponent, DistributorAdminComponent, IndustryAdminComponent, ReportAdminComponent, ReportComponent, RatingsDistributionComponent } from './components';
import { QuickSearchFilterPipe, SafePipe, PermissionFilterPipe, CustomNgbDateParserFormatter } from './_pipes';
import { ActionRendererComponent, DocumentOpenRendererComponent, DocumentHeadlineRendererComponent, DocumentActionComponent, DocumentOpenActionComponent, CheckboxRendererComponent, CustomUnitRendererComponent, CustomHeaderRendererComponent } from './_renderers'; 
import { IndustryreportWithRatingComponent } from './components/documents/industryreportwithratig.component';
import { EstimatesComponent } from './components/admin/estimates/estimates.admin.component';
import { NotificationComponent } from './components/notification.component';

@NgModule({
  declarations: [
    // Components
    AppComponent,
    LoginComponent,
    CardComponent,
    HomeComponent,
    AllDocumentsComponent,
    ActionRendererComponent,
    DocumentOpenRendererComponent,
    DocumentHeadlineRendererComponent,
    CompanyreportComponent,
    IndustryreportComponent,
    AdminComponent,
    UserAdminComponent,
    IssuerAdminComponent,
    DistributorAdminComponent,
    IndustryAdminComponent,
    ReportAdminComponent,
    ReportComponent,
    RatingsDistributionComponent,
    //Pipes
    QuickSearchFilterPipe,
    SafePipe,
    PermissionFilterPipe,
    DocumentActionComponent,
    DocumentOpenActionComponent,
    IndustryreportWithRatingComponent,
    CheckboxRendererComponent,
    CustomUnitRendererComponent,
    CustomHeaderRendererComponent,
    EstimatesComponent,
    NotificationComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    NgxSpinnerModule,
    AgGridModule.withComponents([ActionRendererComponent,
      CheckboxRendererComponent,
      CustomUnitRendererComponent,
      CustomHeaderRendererComponent,
    ]),
    NgbTabsetModule,
    NgbPopoverModule,
    NgbModule,
    DragDropModule,
    // NgbModule.forRoot()
    
  ],
  entryComponents: [AlertComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthenticationService, AuthGuard, AlertService, NgbActiveModal, DataService, AdminService, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter('longDate')}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
