<div class="col mt-2">
  <div class="d-flex flex-row-reverse top_btn d-sm-none d-md-none d-lg-block ">
    <button class="btn btn-primary newdoc mr-2" (click)="openCreateDocumentModal(content)">Create New Document</button>
    <button class="btn btn-primary newdoc mr-2" (click)="loadTableData(selectedTab)">Refresh Document List</button>
  </div>
  <div class="dropdown d-lg-none d-md-none d-sm-block">
    <div class="row">
      <div class="col-3"><label class="control-label st" for="Stage">Stage</label></div>
      <div class="col-9">
        <ng-select (change)="loadTableData($event)" class="" name="selectedStage" [(ngModel)]="stagename" required [clearable]="false" [searchable]="false">
          <ng-option *ngFor="let stage of documentList" [id]="stage" [title]="stage" [value]="stage">
            {{stage}}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <ngb-tabset [activeId]="selectedTab" (tabChange)="loadTableData($event.nextId)">
    <ngb-tab *ngFor="let stage of documentList" [id]="stage" [title]="stage">
      <ng-template ngbTabContent>
        <div class="mt-3 w-25 pull-right d-none d-lg-block">
          <input type="text" class="form-control" placeholder="Quick search" (keyup)="onQuickFilterChanged($event)">
        </div>
        <div class="pull-left mt-3 d-none d-lg-block ">
          Page Size:
          <select (change)="onPageSizeChanged($event)" id="page-size">
            <option selected="" value="20">20</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
        <div [ngStyle]="gridStyle" class="d-none d-lg-block dtable ">
          <ag-grid-angular (gridReady)="onGridReady($event)" [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents" [paginationPageSize]="paginationPageSize" [pagination]="true"
            [rowData]="rowData" [rowHeight]="rowHeight" class="all-doc-table ag-theme-balham pt-3"
            style="clear: both; width: 100%; height: 100%">
          </ag-grid-angular>
        </div>
        <div class="d-block d-lg-none">
          <div class="mt-3 w-100  searchnew  ">
            <input [(ngModel)]="cardQuickSearch" class="form-control" placeholder="Quick search" type="text">
          </div>
          <div *ngFor="let row of rowData | quickSearch : cardQuickSearch" class="mt-3 w-100  document-card card card-3 ">
            <h4 class="card-header text-center text-white bg-blue">
              <span *ngIf="row.isLocked === 'Locked'"><i class="fa fa-lock lockicon" aria-hidden="true"></i></span>
              <app-document-open-action [params]="getMobileCardParam(row, 'documents')"></app-document-open-action>
            </h4>
            <div class="card-body">
              <div class="card_sec">
                <h4 class="card-title">Date Modified : <span class="card-text">{{row.lastModified}}</span></h4>
              </div>
              <div class="card_sec">
                <h4 class="card-title">Last Modified : <span class="card-text">{{row.lastModifiedBy}}</span></h4>
              </div>
              <div class="card_sec border-bottom-0 d-flex justify-content-between">
                <app-document-action [params]="getMobileCardParam(row, 'documents', 'Action')" [user]="user">
                </app-document-action>
                <app-document-action [params]="getMobileCardParam(row, 'documents', 'View')" [user]="user">
                </app-document-action>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
<ng-template #content let-c="close()" let-d="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title">Please select type of document</h4>
    <button type="button" class="close" aria-label="Close" (click)="d">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-cards-panel [adminPanels]="panelData"></app-cards-panel>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="c">Close</button>
  </div>


</ng-template>