import { saveAs } from 'file-saver';

export class UtilityClass {
  extractFileExtension(fileNameWithExtension) {
    const matches = fileNameWithExtension.match(/\.([a-zA-Z0-9]+)\"?$/);
    if (matches && matches.length > 1) {
      const extension = matches[1].replace(/\\/g, '');
      return extension;
    }
    return '';
  }

  downloadDocument(response, docId) {
    var fileName = response.headers.get('Content-Disposition');
    let fileExtension = this.extractFileExtension(fileName);
    if (fileExtension.endsWith('pptx')) {
      const blob = new Blob([response.body], {
        type: 'application/vnd.ms-powerpoint',
      });
      saveAs(blob, `${docId}.pptx`);
    } else if (fileExtension.endsWith('ppt')) {
      const blob = new Blob([response.body], {
        type: 'application/vnd.ms-powerpoint',
      });
      saveAs(blob, `${docId}.ppt`);
    } else if (fileExtension.endsWith('doc')) {
      const blob = new Blob([response.body], {
        type: 'application/vnd.ms-powerpoint',
      });
      saveAs(blob, `${docId}.doc`);
    } else if (fileExtension.endsWith('docx')) {
      const blob = new Blob([response.body], {
        type: 'application/vnd.ms-powerpoint',
      });
      saveAs(blob, `${docId}.docx`);
    }
  }
}
