
     
     <div class="mt-5 text-center">

      <h1 *ngIf="!isPopup" class="mb-3">Estimates and Valuations Default Parameter List</h1>

      
     <div cdkDropListGroup>
      <div class="example-container">
        <h1 class="font-weight-bold">  Parameter List</h1>
        <div
          cdkDropList
          [cdkDropListData]="parameters"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of parameters; trackBy: trackByFn" class="example-box" cdkDrag>{{item.displayName}}</div>
        </div>
      </div>
    
      <div class="example-container">
        <h1 class="font-weight-bold">Estimates</h1>
        <div
          cdkDropList
          [cdkDropListData]="estimates"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of estimates; trackBy: trackByFn" class="example-box" cdkDrag>{{item.displayName}}</div>
        </div>
      </div>
    
      <div class="example-container">
        <h1 class="font-weight-bold">Valuations</h1>
        <div
          cdkDropList
          [cdkDropListData]="valuations"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of valuations; trackBy: trackByFn" class="example-box" cdkDrag>{{item.displayName}}</div>
        </div>
      </div>
    </div>
    </div>
  <div class="form-group row justify-content-center col-sm-12">
      <div class="text-right">
          <button (click)="updateParameter()" class="btn btn-primary" type="submit" >Update</button>
      </div>
      <div class=" ml-3 text-right">
          <button (click)="resetParameter()" class="btn btn-primary" type="submit">Reset</button>
      </div>

      <div *ngIf="isPopup" class=" ml-3 text-right">
        <button (click)="resetToDefaultParameter()" class="btn btn-primary" type="submit">Reset To Default</button>
    </div>
  </div>
    
    