import { currencies } from './commonData';

export class DocumentModel {
  selectedPrimaryIssuer: string;
  selectedPrimaryIndustry: string;
  selectedPrimaryAuthor: any;
  selectedEmailIds: Array<String>;
  financialMatrix: any;
  primaryIssuers: any;
  primaryAuthors: any;
  primaryIndustries: any;
  secondaryIssuers: any;
  filteredSecondaryIssuers: any;
  selectedSubjectCode: string;
  subjectCodes: any;
  targetPrice: string;
  abstract: string;
  investmentRisk: string;
  valuationMethodology: string;
  companyDescription: string;
  selectedRegion: string;
  regions: any;
  selectedSectorRecommendation: string;
  sectorRecommendations: any;
  selectedCurrentRecommendation: string;
  currentRecommendations: any;
  selectedRecommendationAction: string;
  selectedTargetPriceAction: string;
  actions: any;
  selectedCurrency: string;
  currencies: any;
  actionNote: any;
  reportSeriesList: any;
  reportTypeList: any;
  readOnly: any;
  currentPrice:any;
  industryRecommendations: any;
  // priceValidator = (type, { control }) => {
  //   let value = control.value;
  //   let isValid = false;
  //   switch (type) {
  //     case 'currentPrice':
  //       isValid = value === "" || /^\d+\.\d{2}$/.test(value);
  //       break;
  //     case 'fairPrice':
  //       isValid = value === "" || /^\d+\.\d{2}$/.test(value);
  //       break;
  //     case 'singleReportPrice':
  //       isValid = this.actionNote.product.individualSale === 'No' || value === "" || /^\d+\.\d{2}$/.test(value);
  //       break;
  //   }
  //   control.setErrors(isValid ? null : { 'priceValidator': true });
  //   return isValid;
  // };

  priceValidator = (type, { control }) => {
    let value = control.value;
    let isValid = false;
  
    // Regex to match numbers with optional commas and exactly two decimal places
    // const priceRegex = /^(?:\d{1,3}(?:,\d{3})*)?(?:\.\d{2})?$/;
    // const priceRegex = /^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d{2})?$/;
    const priceRegex = /^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d{2})$/;


  
    switch (type) {
      case 'currentPrice':
        isValid = value === "" || priceRegex.test(value);
        break;
      case 'fairPrice':
        isValid = value === "" || priceRegex.test(value);
        break;
      case 'singleReportPrice':
        isValid = this.actionNote.product.individualSale === 'No' || value === "" || priceRegex.test(value);
        break;
    }
  
    control.setErrors(isValid ? null : { 'priceValidator': true });
    return isValid;
  };

  
  

  constructor() {
    this.actionNote = {};
    this.subjectCodes = [
      'Raising Recommendation',
      'Lowering Recommendation',
      'Raising Target Price',
      'Lowering Target Price',
      'Company Update',
      'Industry Overview',
      'Initiation of Coverage',
      'Change in Earnings Forecast',
      'Analyst Presentation',
      'Top Recommendations',
      'Change in Coverage',
      'Terminating Coverage'
    ];
    this.regions = ['region1', 'region2'];
    this.sectorRecommendations = ['Overweight', 'Market Weight', 'Underweight'];
    this.currentRecommendations = [
      'Outperform',
      'Neutral',
      'Underperform'
    ];
    this.industryRecommendations = [
      'Outperform',
      'Speculative Buy',
      'Neutral',
      'Underperform',
      'Under Review',
      'Tender',
      'Suspended',
      'Restricted',
      'Other'
    ];
    this.actions = ['Increase', 'Decrease'];
    this.currencies = currencies;

  }
}
