import { Component, Input } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver/dist/FileSaver';
import { ApiService, AuthenticationService, DataService } from '../_services';
import { environment } from '../../environments/environment';
import { UtilityClass } from '../common/utility';



@Component({
  selector: 'app-document-action',
  template: `
  <div class="d-inline">
  <div class="action-icon float-left">
    <h4 class="card-title d-block d-lg-none" *ngIf="params.colDef.headerName === 'Action'">Action :</h4>
    <a *ngIf="allowAction('unlockdocument')" (click)="clickedAction('unlockdocument', content)">
      <i class="fa fa-lock" ngbPopover="Document locked" triggers="mouseenter:mouseleave" container="body"></i>
    </a>
    <a *ngIf="allowAction('approve')" (click)="clickedAction('approve', content)">
      <i class="fa fa-thumbs-o-up mr-3" ngbPopover="Approve" triggers="mouseenter:mouseleave" container="body"></i>
    </a>
    <a *ngIf="allowAction('reject')" (click)="clickedAction('reject', content)">
      <i class="fa fa-thumbs-o-down mr-3" ngbPopover="Reject" triggers="mouseenter:mouseleave" container="body"></i>
    </a>
    <a *ngIf="allowAction('delete')" (click)="clickedAction('delete', content)">
      <i class="fa fa-trash-o mr-3" ngbPopover="Delete" triggers="mouseenter:mouseleave" container="body"></i>
    </a>
    <a *ngIf="allowAction('recall')" (click)="clickedAction('recall', content)">
      <i class="fa fa-repeat mr-3" ngbPopover="Recall" triggers="mouseenter:mouseleave" container="body"></i>
    </a>
    <a *ngIf="allowAction('resend')" (click)="clickedAction('resend', recipientContent)">
      <i class="fa fa-refresh" ngbPopover="Resend" triggers="mouseenter:mouseleave" container="body"></i>
    </a>

  </div>

  <div class="view" *ngIf="params.colDef.headerName === 'View'">
    <h4 class="card-title d-block d-lg-none">View :</h4>
    <a (click)="downloadPdf(content)">
      <i class="fa fa-file-pdf-o mr-3" ngbPopover="Show PDF" triggers="mouseenter:mouseleave" container="body"></i>
    </a>
    <a (click)="downloadWord()">
      <i class="fa fa-file-word-o wd" ngbPopover="Document document" triggers="mouseenter:mouseleave"
        container="body"></i>
      </a>
  </div>
</div>
<ng-template #content let-c="close()" let-d="dismiss()" let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{headerText}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <object [attr.data]="pdfURL | safe" type="application/pdf" width="100%" height="500"
      *ngIf="params.colDef.headerName === 'View'"></object>
    <span *ngIf="params.colDef.headerName === 'Action'">{{messageBody}}</span>
  </div>
  <div class="modal-footer">
    <div class="pull-left" [ngStyle]="{'visibility': params.colDef.headerName === 'Action' ? 'visible' : 'hidden'}">
      <button type="button" class="btn btn-outline-dark pull-left" (click)="c">{{actionText}}</button>
    </div>
    <div class="pull-right">
      <button type="button" class="btn btn-outline-dark" (click)="d">{{closeText}}</button>
      <button type="button" [ngStyle]="{'visibility': params.colDef.headerName === 'View' ? 'visible' : 'hidden'}"
        class="btn btn-primary pull-left mr-2" (click)="savePdf(); c">Download
      </button>
    </div>
  </div>
</ng-template>
<ng-template #recipientContent let-c="close()" let-d="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{recipientHeaderText}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body row">
    <div class="col-12">
      <div class="form-check col-6" *ngFor="let distributer of distributions">
        <input class="form-check-input" type="checkbox" id="{{distributer.channelName}}"
          [(ngModel)]="distributer.selected" [disabled]="!distributer.editable">
        <label class="form-check-label" [for]="distributer.channelName">{{distributer.channelDescription}}</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="pull-left">
      <button type="button" class="btn btn-outline-dark pull-left" (click)="c">Close</button>
    </div>
    <div class="pull-right">
      <button type="button" class="btn btn-primary" (click)="continueAction(selectedAction); c"
        [disabled]="!checkResendDisabled()">Continue
      </button>
    </div>
  </div>
</ng-template>
`,
  styles: [`
.modal-footer {
  justify-content: space-between;
}

.fa {
  font-size: 1.5em;
}

.card-title{
  font-size: 13px;
  color: #320958;
  font-weight: bold;
  float:left;
  padding-right:5px;
}

`]
})

export class DocumentActionComponent {

  @Input() params: any;
  @Input() user: any;
  pdfURL: any;
  pdf: any;
  closeResult: any;
  messageBody: string;
  closeText: string;
  headerText: string;
  actionText: string;
  minDate: Date;
  setDateTimeLabel: string;
  setLabel: string;
  recipientHeaderText: string;
  distributions: any;
  selectedAction: string;

  constructor(private apiService: ApiService, private modalService: NgbModal,
    public dataService: DataService, private authService: AuthenticationService) {
  }



  allowAction(action): boolean {
    const isActionAllowedInCurrentStage = this.isAllowedForCurrentStage(action);
    switch (action) {
      case 'approve':
        return (this.params.colDef.headerName === 'Action') && this.params.data.permissions.Approve &&
          (this.params.data.isLocked !== 'Locked' && isActionAllowedInCurrentStage);
      case 'delete':
        return (this.params.colDef.headerName === 'Action') && this.params.data.isLocked !== 'Locked' && isActionAllowedInCurrentStage;
      case 'recall':
        return (this.params.colDef.headerName === 'Action') && this.params.data.permissions.Approve && isActionAllowedInCurrentStage;
      case 'resend':
        return (this.params.colDef.headerName === 'Action') && this.authService.isAnyAdmin.value && isActionAllowedInCurrentStage;
      case 'reject':
        return (this.params.colDef.headerName === 'Action') && isActionAllowedInCurrentStage &&
          this.params.data.isLocked !== 'Locked' && this.params.data.permissions.Approve;
      case 'unlockdocument':
        return ((this.params.isMobileView && this.params.colDef.headerName === 'Action') || this.params.colDef.headerName === 'State') && this.params.data.isLocked === 'Locked';
      default:
        return false;
    }
  }

  isAllowedForCurrentStage(action): boolean {
    switch (action) {
      case 'approve':
      case 'unlockdocument':
        return ['Authoring', 'Editing', 'Compliance', 'Production'].includes(this.dataService.selectedStage);
      case 'delete':
        return ['Authoring'].includes(this.dataService.selectedStage);
      case 'recall':
        return ['Editing', 'Compliance', 'Production'].includes(this.dataService.selectedStage);
      case 'resend':
        return ['Recently Published'].includes(this.dataService.selectedStage);
      case 'reject':
        return ['Editing', 'Compliance', 'Production'].includes(this.dataService.selectedStage);
      default:
        return false;
    }
  }


  clickedAction(action, content): void {
    this.selectedAction = action;
    if (action === 'approve') {
      this.apiService.downloadDocument('checkworddocument', this.params.data.documentID).subscribe(() => {
        this.apiService.checkMandatoryMetaData(`${this.params.data.documentType.replace(/\s+/g, '').toLowerCase()}`, {
          documentid: this.params.data.documentID,
          productid: this.params.data.productID,
          issuerid: this.params.data.issuerID
        }).subscribe(() => {
          if (this.dataService.selectedStage === 'Authoring') {
            this.headerText = 'Approve Document from Authoring Stage';
            this.messageBody = 'The analyst(s) primarily responsible for the preparation of this research report attests to the following: ' +
              '(1) that the views and opinions rendered in this research report reflect his or her personal views about the subject companies' +
              ' or issuers; and (2) that no part of the research analyst\'s compensation was, is, or will be directly related to the ' +
              'specific recommendations or views in this research report.';
            this.actionText = 'I certify';
            this.closeText = 'I do not certify';
            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              if (this.params.data.documentType === 'Company Report' && this.params.data.fairValue === this.params.data.priorFairValue) {
                this.headerText = 'Fair Value Price';
                this.messageBody = 'You didn\'t change the fair value price. It is same as prior fair value. Do you still want to approve?';
                this.actionText = 'Yes';
                this.closeText = 'No';
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                  this.continueAction('update');
                });
              } else {
                this.continueAction('update');
              }
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          } else if (this.dataService.selectedStage === 'Compliance') {
            this.headerText = 'Approve Document from Compliance Stage';
            this.messageBody = 'The Supervisory Analyst attests to the review and approval of the substance of this research report';
            this.actionText = 'Ok';
            this.closeText = 'Cancel';
            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.continueAction('update');
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          } else {
            this.headerText = 'Approve';
            this.messageBody = 'Do you want to approve this document?';
            this.actionText = 'Yes';
            this.closeText = 'No';
            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(() => {
              this.continueAction('update');
            });
          }
        })
      })

    } else if (action === 'resend') {
      this.recipientHeaderText = 'Resend Document';
      this.apiService.getDistributionRecipients(`${this.params.data.researchId}/false`).subscribe(({ data }) => {
        this.distributions = data;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
    } else if (action === 'unlockdocument') {
      this.continueAction(action);
    } else if (action === 'delete') {
      this.headerText = 'Delete';
      this.messageBody = 'Are you sure you want to delete this document?';
      this.actionText = 'Continue';
      this.closeText = 'Cancel';
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        this.continueAction(action);
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else if (action === 'reject' || action === 'recall') {
      this.headerText = `${action[0].toUpperCase()}${action.slice(1)}`;
      this.messageBody = `Do you want to ${action} this document?`;
      this.actionText = 'Yes';
      this.closeText = 'No';
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(() => this.continueAction(action))
    }
    else {
      this.continueAction(action);
    }

  }


  continueAction(action) {
    this.params.api.componentParent.onActionCellClicked(action, this.params.data, this.distributions);
  }

  savePdf(): void {
    saveAs(this.pdf, `${this.params.data.documentID}.pdf`);
    this.pdf = void 0;
  }

  downloadPdf(content): void {
    this.pdf = void 0;
    if (this.authService.isMobileDevice) {
      this.apiService.checkPDF(this.params.data.documentID)
        .subscribe((res) => {
          if (res) {
            window.open(`${environment.API_URL}/services/documents/downloadpdf/${this.params.data.documentID}`, '_self')
          }
        });
    } else {
      this.apiService.downloadPDF(this.params.data.documentID).subscribe(response => {
        this.pdf = new Blob([response.body], { type: 'application/pdf' });
        this.pdfURL = window.URL.createObjectURL(this.pdf);
        this.headerText = 'PDF';
        this.closeText = 'Close';
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
    }
  }

  downloadWord(): void {
    this.apiService.downloadDocument('downloaddocument', this.params.data.documentID).subscribe(response => {
     
      let util = new UtilityClass();
      util.downloadDocument(response, this.params.data.documentID);

    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checkResendDisabled() {
    if (this.distributions) {
      return this.distributions.some(function (distributer) {
        return distributer.selected;
      });
    } else {
      return false;
    }
  }



}
