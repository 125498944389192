import { Component, Input } from '@angular/core';
import { DataService } from '../_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-open-action',
  template: `<span><a class=" docid" (click)="openDocument()" *ngIf="this.params.data.permissions.View">{{this.params.value}}</a></span>
  <span *ngIf="!this.params.data.permissions.View">{{this.params.value}}</span> `
  ,
  styles: [`

.docid{
   color:#007bff ;
  }

@media only screen and (max-width: 992px) and (min-width: 320px)  {

  .docid{
     color:#fff;
    }

}

`]
})
export class DocumentOpenActionComponent {
  @Input() params: any;

  constructor(private dataService: DataService, private router: Router) {
  }



  openDocument(): void {
    this.dataService.openDocumentData = this.params.data;
    const queryParams = {
      documentid: this.params.data.documentID,
      productid: this.params.data.productID,
      issuerid: this.params.data.issuerID
    }
    if (this.params.data.documentType === 'Industry Comment') {
      this.router.navigate(['/industryreport/open'], { queryParams });
    }
    else if(this.params.data.documentType ===  'Industry With Rating'){
      this.router.navigate(['/industrywithratingreport/open'], { queryParams });
    }
    else {
      this.router.navigate(['/companyreport/open'], { queryParams });
    }
  }

}
