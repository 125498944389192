import { Component, OnInit } from '@angular/core';
import { AdminPanel } from '../../common/AdminPanel';
import { panels as panelData } from '../../common/panelData';
import {saveAs} from 'file-saver/dist/FileSaver';
import { ApiService, AuthenticationService, DataService } from '../../_services';
import _ from 'lodash';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
  adminPanels = [];
  isAdmin: Boolean;
  panels: any;

  constructor(private api: ApiService, private authService: AuthenticationService, private dataService: DataService) {
    const user = this.authService.user.getValue();
    this.isAdmin = this.authService.isAnyAdmin.value;
    this.panels = _.cloneDeep(panelData);
  }

  addHomePanel() {
    if (this.isAdmin) {
      this.panels.filter(panel => !['Report Series', 'Report Type', 'Ratings Distribution', 'filler', 'Estimates and Valuations'].includes(panel.name)).forEach(panel => {
        this.adminPanels.push(
          new AdminPanel(
            panel.icon,
            panel.iconUrl,
            panel.name,
            panel.description,
            panel.options
          )
        );
      });
    } else {
      this.panels.filter(panel => ['Company Documents', 'Industry Documents', 'Add-in installer']
        .includes(panel.name)).forEach(panel => {
          this.adminPanels.push(
            new AdminPanel(
              panel.icon,
              panel.iconUrl,
              panel.name,
              panel.description,
              panel.options
            )
          );
        });
    }
  }

  downloadInstaller(value) {
    if (['WordAddin', 'ExcelAddin'].includes(value.type)) {
      this.api.downloadAddInInstaller(value.type).subscribe(response => {
        const blob = new Blob([response.body], {type: 'application/zip'});
        saveAs(blob, this.dataService.getFilename(response, 'installer.zip'));
      });
      
    } else if ('BlankWorkbook' === value.type) {
      this.api.downloadBlankWorkbook().subscribe(response => {
        const blob = new Blob([response.body], {type: 'application/msexcel'});
        saveAs(blob, this.dataService.getFilename(response, 'BlankWorkBook.xlsx'));
      });
  }

  }
  ngOnInit() {
    this.addHomePanel();
  }
}
