import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent, AllDocumentsComponent, CompanyreportComponent, DistributorAdminComponent, HomeComponent, IndustryAdminComponent, IndustryreportComponent, IssuerAdminComponent, LoginComponent, NotFoundComponent, UserAdminComponent, ReportAdminComponent, ReportComponent, RatingsDistributionComponent } from './components';
import { AuthGuard } from './_helpers';
import { IndustryreportWithRatingComponent } from './components/documents/industryreportwithratig.component';
import { EstimatesComponent } from './components/admin/estimates/estimates.admin.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'documents', component: AllDocumentsComponent, canActivate: [AuthGuard] },
  { path: 'documents/:action', component: AllDocumentsComponent, canActivate: [AuthGuard] },
  { path: 'companyreport/:action', component: CompanyreportComponent, canActivate: [AuthGuard] },
  { path: 'industryreport/:action', component: IndustryreportComponent, canActivate: [AuthGuard] },
  { path: 'industrywithratingreport/:action', component: IndustryreportWithRatingComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'users/:action', component: UserAdminComponent, canActivate: [AuthGuard] },
  { path: 'issuers/:action', component: IssuerAdminComponent, canActivate: [AuthGuard] },
  { path: 'distributors/ratings', component: RatingsDistributionComponent, canActivate: [AuthGuard] },
  { path: 'distributors/:action', component: DistributorAdminComponent, canActivate: [AuthGuard] },
  { path: 'industries/:action', component: IndustryAdminComponent, canActivate: [AuthGuard] },
  { path: 'report/:type/:action', component: ReportAdminComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'estimates', component: EstimatesComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
