import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../_services/notification.service';

@Component({
  selector: 'app-notification',
  template: `
     <div *ngIf="message" [ngClass]="type" class="notification" (mouseenter)="pauseTimer()" 
     (mouseleave)="resumeTimer()">
      <div class="toasts" >
        <div class="toasts-body">
        <div class="d-flex align-items-center">
        <div class="flex-shrink-0 me-2">
          <i class="ri-alert-line align-middle"></i>
        </div>
        <div class="flex-grow-1">
          <h6 class="mb-0" style="font-weight: 600;">{{message}}<a href="javascript:void(0);" class="text-decoration-underline"></a></h6>
        </div>
      </div></div></div>
      
    </div>
<!-- <div [ngClass]="['notification', type]" style="z-index: 1090">
  <div class="toast" [ngClass]="{'toast-border-danger': type === 'error', 'toast-border-success': type === 'success'}">
    <div class="toast-body">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 me-2">
          <i class="ri-alert-line align-middle"></i>
        </div>
        <div class="flex-grow-1">
          <h6 class="mb-0">we are testing here what to do so  <a href="javascript:void(0);" class="text-decoration-underline">See detailed report.</a></h6>
        </div>
      </div>
    </div>
  </div>
</div> -->

  `,
  styles: [`
    .notification {
      position: fixed;
      top: 20px;
      right: 20px;
      padding: 7px;
      border-radius: 5px;
      z-index: 1090;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
    .success {
      background-color: #51a351; /* Green */
      color: #fff;
    }
    .error {
      background-color: #bd362f; /* Red */
      color: #fff;
    }

.toasts {
  display: flex;
  align-items: center;
  max-width: 300px;
  border: 1px solid transparent;
  color:white;
  // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.toast-border-danger {
  border-color: #dc3545;
}

.toasts-body {
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  }

.flex-shrink-0 {
  flex-shrink: 0;
}

.me-2 {
  margin-right: 0.5rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-middle {
  vertical-align: middle;
}

.text-decoration-underline {
  text-decoration: underline;
}

.mt-3 {
  margin-top: 1rem;
}

.success {
  border-color: #28a745; /* Green */
}

.error {
  border-color: #dc3545; /* Red */
}

.warning {
  border-color: #ffc107; /* Yellow */
}


  `]
})
export class NotificationComponent implements OnInit {
  message: string;
  type: 'success' | 'error';
  private timer: any;


  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.getNotification().subscribe(notification => {
      this.message = notification.message;
      this.type = notification.type;
      // setTimeout(() => this.message = '', 5000);
      this.startTimer();
    });
  }

  startTimer() {
    this.clearTimer(); // Clear any existing timer
    this.timer = setTimeout(() => this.message = '', 4000);
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  pauseTimer() {
    this.clearTimer();
  }

  resumeTimer() {
    this.startTimer();
  }
}
