<div class="d-flex justify-content-center">

  <div class="" id="loginbox">
    <div class="card">
      <div class="card-header text-center logo">
        <img id="card-img-top" src="../../assets/brand_logo.png">
      </div>
      <div class="card-body">
        <form name="form" id="form" class="form-horizontal loginForm" #loginForm="ngForm">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-user"></i></span>
            </div>
            <input id="user" name="user" type="text" class="form-control" [(ngModel)]="user" #userName="ngModel"
              placeholder="User" required />
          </div>
          <div [hidden]="userName.valid || userName.pristine" class="alert alert-danger">Name is required</div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-lock"></i></span>
            </div>
            <input id="password" name="password" type="password" class="form-control" [(ngModel)]="pass"
              #password="ngModel" placeholder="Password" required />
          </div>
          <div [hidden]="password.valid || password.pristine" class="alert alert-danger">Password is required</div>

          <div class="text-center">
            <button type="submit" (click)="onSubmit(content)" class="btn btn-primary"
              [disabled]="!loginForm.form.valid">
              <i class="fa fa-sign-in"></i> Log in
            </button>
          </div>

        </form>
        <div class="mt-5">
          <p class="copyRight pull-right">Powered by Jovus</p>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-c="close()" let-d="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title">Change Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="d">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePasswordForm">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" class="form-control" formControlName="username" disabled>
      </div>
      <div class="form-group">
        <label for="oldPassword">Current Password:</label>
        <input type="password" id="oldPassword" class="form-control" formControlName="oldPassword">
      </div>
      <div class="form-group">
        <label for="newPassword">New Password:</label>
        <input type="password" id="newPassword" class="form-control" formControlName="newPassword">
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm Password:</label>
        <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
      </div>
      <div class="alert alert-danger"
        *ngIf="changePasswordForm.errors?.MatchPassword && (changePasswordForm.touched || changePasswordForm.dirty)">
        {{changePasswordForm.errors?.message}}
      </div>
      <div class="pull-right">
        <button type="button" class="btn btn-outline-dark pull-left" [disabled]="!changePasswordForm.valid"
          (click)="change(); c">
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>