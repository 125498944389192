export let panels = [
  // Add User Administration Panel.
  {
    icon: 'fa-users',
    iconUrl: '/users/allUser',
    name: 'User',
    description: 'Admin page for user administration',
    options: [
      {optionName: 'Create New User', optionUrl: '/users/newUser', enabled: true},
      {optionName: 'View All Users', optionUrl: '/users/allUser', enabled: true}
    ]
  },
  // Add Issuer Administration Panel.
  {
    icon: 'fa-registered',
    iconUrl: '/issuers/allIssuer',
    name: 'Issuer',
    description: 'Admin page for issuer administration',
    options: [
      {optionName: 'Create New Issuer', optionUrl: '/issuers/newIssuer', enabled: true},
      {optionName: 'View All Issuers', optionUrl: '/issuers/allIssuer', enabled: true}
    ]
  },
  // Add Distributors Administration Panel
  {
    icon: 'fa-suitcase',
    iconUrl: '/distributors/allDistributor',
    name: 'Distributors',
    description: 'Admin page for distributors administration',
    options: [
      {
        optionName: 'Create New Distributor',
        optionUrl: '/distributors/newDistributor',
        enabled: false
      },
      {
        optionName: 'View All Distributors',
        optionUrl: '/distributors/allDistributor',
        enabled: true
      }
    ]
  },
  // Add Industries Administration Panel
  {
    icon: 'fa-steam-square',
    iconUrl: '/industries/allIndustries',
    name: 'Industries',
    description: 'Admin page for industries administration',
    options: [
      {
        optionName: 'Create New Industry',
        optionUrl: '/industries/newIndustry',
        enabled: true
      },
      {
        optionName: 'View All Industries',
        optionUrl: '/industries/allIndustries',
        enabled: true
      }
    ]
  },
  // Add Panel for creating new documents
  {
    icon: 'fa-file-text-o',
    iconUrl: '/home',
    name: 'Company Documents',
    description: 'This page for creating new company reports',
    options: [
      {
        optionName: 'Company Report',
        optionUrl: '/companyreport/create',
        enabled: true
      }
    ]
  },
  // Add Other Documents Panel
  {
    icon: 'fa-file-text-o',
    iconUrl: '/home',
    name: 'Industry Documents',
    description: 'This page for creating other new documents',
    options: [
      {
        optionName: 'Industry Comment',
        optionUrl: '/industryreport/create',
        enabled: true
      },
      {
        optionName: 'Industry Comment With Rating',
        optionUrl: '/industrywithratingreport/create',
        enabled: true
      }
    ]
  },
  {
    icon: 'fa-download',
    iconUrl: '/home',
    name: 'Add-in installer',
    description: 'Link to download Add-in installer',
    options: [
      {
        optionName: 'Download Add-In',
        type:'WordAddin',
        optionUrl: '/home',
        enabled: true
      },
      // {
      //   optionName: 'Excel Add-in',
      //   type: 'ExcelAddin',
      //   optionUrl: '/home',
      //   enabled: true
      // },
      {
        optionName: 'Blank Workbook',
        type: 'BlankWorkbook',
        optionUrl: '/home',
        enabled: true
      }
    ]
  },
  // View Panel for Report Series and Types
  {
    icon: 'fa-list-alt',
    iconUrl: '/report/series/list',
    name: 'Report Series',
    description: 'Admin page for report series',
    options: [
      {
        optionName: 'Create New Report Series',
        optionUrl: '/report/series/create',
        enabled: true
      },
      {
        optionName: 'View All Report Series',
        optionUrl: '/report/series/list',
        enabled: true
      }
    ]
  },
  {
    icon: 'fa-list-alt',
    iconUrl: '/report/type/list',
    name: 'Report Type',
    description: 'Admin page for report types',
    options: [
      {
        optionName: 'Create New Report Type',
        optionUrl: '/report/type/create',
        enabled: true
      },
      {
        optionName: 'View All Report Type',
        optionUrl: '/report/type/list',
        enabled: true
      }
    ]
  },
  {
    icon: 'fa-percent',
    iconUrl: '/distributors/ratings',
    name: 'Ratings Distribution',
    description: 'Admin page for rating distribution',
    options: [
      {
        optionName: 'View',
        optionUrl: '/distributors/ratings',
        enabled: true
      }
    ]
  },
  {
    icon: 'fa-cog',
    iconUrl: '/estimates',
    name: 'Estimates and Valuations',
    description: 'Set default values for estimates and valuations',
    options: [
      {
        optionName: 'Configure',
        optionUrl: '/estimates',
        enabled: true
      }
    ]
  },
  // Filler Card to align cards
  {
    icon: 'filler',
    iconUrl: 'filler',
    name: 'filler',
    description: 'filler',
    options: []
  },
];
