import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../_services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../../_helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  submitted = false;
  returnUrl: string;
  changePasswordForm: FormGroup;
  user = '';
  pass = '';
  content: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService, private modalService: NgbModal) {
    this.changePasswordForm = new FormGroup({
      username: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    }, {
      validators: PasswordValidation
    });
  }

  ngOnInit() {

    this.user = '';
    this.pass = '';

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.redirect();
  }

  redirect() {
    if (this.authenticationService.user.value) {
      if (this.authenticationService.isAnyAdmin.value && !this.authenticationService.isMobileDevice) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate(['/documents']);
      }
    }
  }


  onSubmit(content) {
    this.submitted = true;
    this.content = content;
    this.loading = true;
    this.authenticationService.login(this.user, this.pass, content).subscribe(
      user => {
        this.authenticationService.user.next(user);
        this.authenticationService.isAnyAdmin.next(user.authorities.some(auth => ['PA', 'SA', 'UA'].includes(auth.authority)));
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this.redirect();
      },
      error => {
        this.authenticationService.user.next(null);
        this.pass = '';
        this.changePasswordForm.setValue({
          username: this.user,
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
        this.changePasswordForm.setErrors({ MatchPassword: false });
        if (error.exceptionCode === 'AUTH_ERR_02') {
          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        }
      }
    );
  }

  change(): void {
    const requestBody = {
      personID: '',
      creation: null,
      oldPassword: this.changePasswordForm.get('oldPassword').value,
      password: this.changePasswordForm.get('newPassword').value,
      iscurrent: null
    };
    this.authenticationService.changePassword(this.user, requestBody).subscribe(data => { }, error => {
      this.changePasswordForm.setValue({
        username: this.user,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      if (error.exceptionCode === 'PERSON_PASS_DU_13') {
        this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title' });
        this.changePasswordForm.setErrors({ MatchPassword: true, message: error.errorMessage });
      }
    });
  }
}
