import { Component } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { saveAs } from 'file-saver';
import { ApiService, AuthenticationService, DataService } from '../_services';

@Component({
  selector: 'app-document-action-cell',
  template: `<app-document-action [params]="params" [user]="user"></app-document-action>   `,
  
})
export class ActionRendererComponent implements ICellRendererAngularComp {

  params: any;
  user: any;
  

  constructor(private apiService: ApiService, private modalService: NgbModal,
    public dataService: DataService, private authService: AuthenticationService) {
  }

  agInit(params: any): void {
    this.params = params;
    this.user = this.authService.user.getValue();
  }

  

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  

}



