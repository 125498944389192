import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../../_services';
import * as moment from 'moment';
import {Router, ActivatedRoute} from '@angular/router';
import _ from 'lodash';

@Component({
  templateUrl: 'report.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class ReportAdminComponent implements OnInit {
  private gridApi;
  createView = false;
  isReportType: any;
  reportDef = [];
  report = {};
  rowHeight = 40;
  paginationPageSize = 20;
  rowSelection = 'single';
  selectedRow: any;
  monthsList = moment.months();
  actionLabel = 'Update';
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: `${this.isReportType ? 'TypeID' : 'SeriesID'}`,
      field: 'id',
      width: 560,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: `${this.isReportType ? 'Report Types' : 'Report Series'}`,
      field: 'value',
      width: 560,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }
  ];


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  getRowNodeId({ id }) {
    return id;
  }

  onSelectionChanged() {
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
  }

  onSaveReport() {
    this.adminService.saveReport(this.isReportType ? 'reporttype' : 'reportseries', this.selectedRow).subscribe(() => {
      if (!this.createView) {
        const rowNode = this.gridApi.getRowNode(this.selectedRow.id);
        rowNode.setData(this.selectedRow);
      }
    });
  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  constructor(
    private adminService: AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.params.subscribe(param => {
      this.isReportType = param.type === 'type';
      this.createView = param.action === 'create';
    })
  }

  resetHeight() {
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    if ( window.innerHeight > 1000 ) {
      availableHeight = window.innerHeight - 600;
    }
    /*Get the HOT length*/
    const hotData = this.reportDef || [];
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * this.rowHeight) + 50;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  ngOnInit() {
    if (this.createView) {
      this.selectedRow = {
        isActive: true,
        value: ""
      };
      this.actionLabel = 'Save';
    };
    this.adminService.getAllReports(this.isReportType ? 'reporttype' : 'reportseries').subscribe(({data}) => {
        this.reportDef = data;
        this.resetHeight();
    });
  }
}
