import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService, ApiService, AuthenticationService, DataService } from '../../_services';
import { DocumentModel } from './document.model';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { UtilityClass } from 'src/app/common/utility';
import { BehaviorSubject } from 'rxjs';
import { CheckboxRendererComponent, CustomHeaderRendererComponent, CustomUnitRendererComponent } from "../../_renderers";

import _ from 'lodash';
import { set } from 'lodash';

import { companyColumnDefs, fixedEarningSummaryColumns } from './commonData';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders } from '@angular/common/http';
import { NotificationService } from 'src/app/_services/notification.service';


@Component({
  selector: 'app-select-bindings',
  templateUrl: './companyreport.component.html',
  styleUrls: ['./document.component.scss']
})
export class CompanyreportComponent implements OnInit {
  formModel: DocumentModel;
  formEnabled: boolean;
  params: any;
  queryParams: any;
  selectedTab: String;
  showModel: any;
  comments: string;
  loggedInUserName: string;
  checkInDocument: any;
  checkInDocumentPath: string;
  defaultReportType: any;
  @ViewChild('documentForm')
  documentForm: NgForm
  selectedFinTab: string;
  isResetEnabled: boolean = true;
  companyGridApi: any;
  companyGridColumnApi: any;
  companyRowData: any;
  // companyRowDataOriginal: any;
  companyColumnDefs: any;
  frameworkComponents: any
  gridOptions: any;
  earningGridApi: any;
  earningColumnDefs: any;
  selectedFirstYear: any;
  earningSummary: any;
  selectedLastYear: any;
  yearArray: any;
  earningGridColumnApi: any;
  earningRowData: any;
  // earningRowDataOriginal: any;
  earningGridStyle = {
    height: "400px",
  };  
  companyGridStyle = {
    height: "400px",
  };  
  revertCompanyStatistics: any;
  revertCompanyStatisticsClone: any;

  allIssuers: any;
  earningYears: any;
  earningValues: any;
  // earningValuesOriginal: any;
  earningDynamicColums: any;
  // earningDynamicColumnsOriginal: any;
  resetMatrixValue = new BehaviorSubject(false);
  gridParams: any;

  modifiedRowData: {
    companyStatisticsOld: any,
    earningSummaryOld: any,
    earningSummaryDynamicOld: any,
    earningSummaryDynamicHeaderOld: any,
    companyStatistics: any [],
    earningSummary: any[],
    earningSummaryDynamic: any[],
    earningSummaryDynamicHeader: any[],
    firstYearOld:any,
    lastYearOld: any,
    firstYear:any,
    lastYear:any,
    isYearChange:boolean,
  };

  financialMatrixCurrency = [
    'CAD',
    'USD',
    'EUR',
    'GBP',
    'GBp',
    'CHF',
    'SEK',
    'DKK',
    'NOK',
    'CNY',
    'JPY',
    'KRW',
    'HKD',
    'TWD',
    'SCD',
    'AUD',
    'ZAR',
    'BRL'
  ]

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private zone: NgZone

  ) {
    const self = this;
    this.showModel = true;
    this.formModel = new DocumentModel();
    this.formEnabled = false;
    this.route.params.subscribe(params => (this.params = params));
    this.route.queryParams.subscribe(params => this.queryParams = params);
    this.companyColumnDefs = companyColumnDefs;
    this.earningColumnDefs = fixedEarningSummaryColumns;
    this.frameworkComponents = {
      checkBoxRenderer: CheckboxRendererComponent,
      agColumnHeader: CustomHeaderRendererComponent,
      unitRenderer: CustomUnitRendererComponent,
    };
    this.gridOptions = {
      onCellValueChanged: function () {
        this.isResetEnabled = true;
      },

    };
    this.checkInDocumentPath = 'Choose file';
    this.selectedTab = 'Metadata';
    this.selectedFinTab = "companyStatistics";

    this.modifiedRowData = {
      companyStatisticsOld: [],
      earningSummaryOld: [],
      earningSummaryDynamicOld: [],
      earningSummaryDynamicHeaderOld: [],
      companyStatistics: [], 
      earningSummary: [],
      earningSummaryDynamic: [],
      earningSummaryDynamicHeader: [],
      firstYearOld: null,
      lastYearOld: null,
      firstYear: null,
      lastYear: null,
      isYearChange: false,

    };
    
  }

  createDocument(event): void {
    const requestBody = {
      personid: this.authService.user.value.personID,
      issuerid: event,
    };
    this.apiService
      .createDocument('companyreport', requestBody)
      .subscribe(response => {
        const queryParams = {
          documentid: response.data.documentID,
          productid: response.data.productID,
          issuerid: response.data.issuerID
        };
        this.router.navigate(['/companyreport/open'], { queryParams });
        this.setInitialData(response);
      });
  }
  saveOnTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      switch (this.selectedTab) {
        case 'Metadata':
          this.onSave();
          break;
        case 'Comments':
          this.onAddComment();
          break;
          case 'FinancialMatrix':
        if (this.selectedFinTab == "companyStatistics") {
          this.saveCompanyStatistics("update");
          this.selectedFinTab = "companyStatistics";
        } else {
          this.saveEarningSummary("update");
          this.selectedFinTab = "earningSummary";
        }
        case 'AuthorDocument':
          break;
        case 'DistributionRecipients':
          this.saveDistribution();
          break;
        default:
          break;
      }
    }
    this.selectedTab = tab;
  }

  saveOnFinTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      if (this.selectedFinTab == "companyStatistics") {
        this.saveCompanyStatistics("update");
      } else {
        this.saveEarningSummary("update");
      }
    }

    this.selectedFinTab = tab;
  }
  reset() {
    this.formModel.actionNote.status.primaryAuthors = false;
    this.formModel.actionNote.status.productsStatus = false;
    this.formModel.actionNote.status.secondaryIssuers = false;
    this.formModel.actionNote.status.prodIssuersStatus = false;
    this.formModel.actionNote.status.secondaryIssuers = false;
    this.formModel.actionNote.status.secondaryIndustries = false;
    this.formModel.actionNote.status.primaryIssuers = false;
    this.formModel.actionNote.status.distributionStatus = false;
    this.formModel.actionNote.status.companyEarningsHeaderStatus = false;    
    this.formModel.actionNote.status.companyEarningsParameterStatus = false;
    this.formModel.actionNote.status.companyEarningsValueStatus = false;
  }
  onSave() {
    this.formModel.actionNote.status.primaryAuthors = true;
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.status.secondaryIssuers = true;
    this.formModel.actionNote.status.prodIssuersStatus = true;
    this.formModel.actionNote.status.companyStatisticsStatus = true;
    this.formModel.actionNote.status.companyEarningsHeaderStatus = true;    
    this.formModel.actionNote.status.companyEarningsParameterStatus = true;
    this.formModel.actionNote.status.companyEarningsValueStatus = true;


   
    let currentPrice = this.formModel.actionNote.currentPrice;
    let tp = this.formModel.actionNote.prodIssuers.targetPrice;
    if (currentPrice && !currentPrice.toString().includes('.')) {
      currentPrice = `${currentPrice}.00`; 
    } else if (currentPrice && currentPrice.toString().split('.')[1].length === 1) {
      currentPrice = `${currentPrice}0`; 
    }
     
    // this.setCurrentPrice(this.formModel.actionNote.financialMatrix);

    // tp = this.validatePriceDecimalWithNumberFormat(tp);

    this.formModel.actionNote.currentPrice = currentPrice;
    const financialValue = new FormControl(currentPrice);
    const targetPrice = new FormControl(tp);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }
    currentPrice = this.validatePriceDecimalWithNumberFormat(currentPrice);

    if(this.selectedTab === 'Metadata'){
      this.updateFinancialValue(this.formModel.actionNote.financialMatrix.statistics, 'Price', currentPrice);
    }
    
    this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe({next:(res)=>{
        this.setCurrentPrice(res.data.financialMatrix);
        this.setFairVaule(res.data.prodIssuers);
      }, error: ()=>{}, complete: () => this.reset()});
  }

  // validatePriceDecimalWithNumberFormat(currentPrice){
  //   if (currentPrice) {
  //     let priceStr = currentPrice.toString();
  //     if (!priceStr.includes('.')) {
  //       priceStr = `${priceStr}.00`;
  //     } else if (priceStr.split('.')[1].length === 1) {
  //       priceStr = `${priceStr}0`; 
  //     }
  //   console.log(currentPrice);
  //     currentPrice = new Intl.NumberFormat('en-US', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2
  //     }).format(parseFloat(priceStr));
  //   }
  //   return currentPrice;
  // }

  validatePriceDecimalWithNumberFormat(currentPrice) {
    const isFormatted = typeof currentPrice === 'string' && currentPrice.includes(',');
  
    if (isFormatted) {
      return currentPrice;
    }
  
    if (currentPrice) {
      let priceStr = currentPrice.toString();
      
      if (!priceStr.includes('.')) {
        priceStr = `${priceStr}.00`;
      } else if (priceStr.split('.')[1].length === 1) {
        priceStr = `${priceStr}0`; 
      }
  
      currentPrice = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(parseFloat(priceStr));
    }
  
    return currentPrice;
  }
  

  onSaveBypassInterceptor() {
    this.formModel.actionNote.status.primaryAuthors = true;
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.status.secondaryIssuers = true;
    this.formModel.actionNote.status.prodIssuersStatus = true;
    this.formModel.actionNote.status.companyStatisticsStatus = true;
    this.formModel.actionNote.status.companyEarningsHeaderStatus = true;    
    this.formModel.actionNote.status.companyEarningsParameterStatus = true;
    this.formModel.actionNote.status.companyEarningsValueStatus = true;


    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(
        {next:(res)=>{
          this.setCurrentPrice(res.data.financialMatrix);
        }, error: () =>{},complete: () => this.reset()
    });

  }

  

  onClose() {

    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    if ( !this.formModel.actionNote.readOnly ) {
      this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(() => {
        this.apiService
          .documentAction('unlockdocument', {
            productid: this.formModel.actionNote.productID,
            personid: this.authService.user.value.personID
          })
          .subscribe(data => this.router.navigate(['/documents'], { queryParams: { stage: this.dataService.selectedStage } }));
      }, null, () => this.reset());
    } else {
      this.router.navigate(['/documents'], { queryParams: { stage: this.dataService.selectedStage }});
    }



  }
  getComment(): string {
    const timeStamp = new Date();
    let comment = '';
    const previousComments = this.formModel.actionNote.product.comments || '';
    const currentComments = this.comments ? `${this.loggedInUserName} ${timeStamp.toString().substr(0, 33)}  :  ${this.comments}` : '';
    if (previousComments === '') {
      comment = `${currentComments}`;
    } else {
      comment = `${previousComments} <br />${currentComments}`;
    }
    this.formModel.actionNote.comments = comment;
    return comment;
  }
  onAddComment() {
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.product.comments = this.getComment();

    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(data => this.comments = '', null, this.reset);
  }
  setInitialData({ data }) {
    this.formModel.actionNote = data;
    this.formModel.actionNote.prodIssuers.defaultCurrency = this.formModel.actionNote.prodIssuers.defaultCurrency || 'USD';
    this.formModel.selectedPrimaryIssuer = this.formModel.actionNote.prodIssuers.issuer.issuerID;
    this.formEnabled = true;
    this.formModel.actionNote.personID = this.authService.user.value.personID;
    this.formModel.primaryAuthors = data.allAnalyst;
    this.formModel.actionNote.prodIssuers.reportTypeId = this.formModel.reportTypeList.some(function (value) { return value.id === data.prodIssuers.reportTypeId }) ?
      data.prodIssuers.reportTypeId : (this.defaultReportType ? this.defaultReportType.id : null);
    this.yearArray = [];
    this.earningSummary = data.financialMatrix.earnings_values;
    this.revertCompanyStatistics = this.formModel.actionNote.financialMatrix;
    this.revertCompanyStatisticsClone = _.cloneDeep(this.revertCompanyStatistics);
    this.earningYears = _.keysIn(this.earningSummary);
    if(this.allIssuers)
      this.allIssuers = this.allIssuers.filter(e=> e.issuerID !== data.prodIssuers.issuerID )
     
    this.setFairVaule(this.formModel.actionNote.prodIssuers);
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);

  }

  ngOnInit(): void {
    this.authService.user.subscribe(user => this.loggedInUserName = user.displayName);
    this.apiService.getPrimaryIssuers(this.authService.user.value.personID).subscribe(({ data }) => (this.formModel.primaryIssuers = data));
    this.apiService.getReportTypeSeries('reportseries', true).subscribe(({ data }) => this.formModel.reportSeriesList = data);
    this.apiService.getReportTypeSeries('reporttype', true).subscribe(({ data }) => {
      this.formModel.reportTypeList = data;
      this.defaultReportType = data.find(row => row.value === 'Company-Specific');
      this.apiService.getAllIssuers().subscribe(({data})=>{
        this.allIssuers = data;
      });
      if (this.params.action === 'open') {
        const requestBody = { ...this.queryParams, personid: this.authService.user.value.personID };
        this.apiService.openDocument('companyreport', requestBody).subscribe(data => {
          this.setInitialData(data);
        }, err => this.router.navigate(['/documents']));
      }
    });
  }
  getBlankTemplate(): void {
    this.apiService.downloadDocument('companyreport', this.formModel.actionNote.documentID).subscribe(response => {
      const blob = new Blob([response.body], { type: 'application/msword' });
      let util = new UtilityClass();
      util.downloadDocument(response, this.formModel.actionNote.documentID);
    });
  }

  checkOutDocument(): void {
    this.apiService.downloadDocument('downloaddocument', this.formModel.actionNote.documentID).subscribe(response => {
      const blob = new Blob([response.body], { type: 'application/msword' });
      let util = new UtilityClass();
      util.downloadDocument(response, this.formModel.actionNote.documentID);
    });
  }
  onFileChange(event, fileInput): void {
    this.checkInDocument = fileInput.files[0];
    this.checkInDocumentPath = fileInput.files[0].name;
    fileInput.value = null;
  }
  checkIn(): void {
    const formData = new FormData();
    formData.append('uploadDocument', this.checkInDocument);
    this.apiService.uploadDocument(this.formModel.actionNote.documentID, formData).subscribe(() => {
      this.checkInDocument = null;
      this.checkInDocumentPath = 'Choose file';
    });
  }
  changexlxs(event, file, content): void {
    this.modalService.open(content).result.then(
      (result) => {

        const formData = new FormData();
        formData.append("excelDocument", file.files[0]);
        formData.append('productid', this.formModel.actionNote.productID?.toString() || '');
        formData.append('personid', this.formModel.actionNote.personID?.toString() || '');
        // formData.append('currency', this.formModel.actionNote.product.defaultDisplayCurrency || '');
  

        // this.apiService.uploadFinancialModel(formData, this.formModel.actionNote.productID, this.formModel.actionNote.personID, this.formModel.actionNote.product.defaultDisplayCurrency).subscribe(
          this.apiService.uploadFinancialModel(formData).subscribe(
          (response) => {
            this.importFinancialMatrix();
            file.value = null;
          },
          (error) => {
            file.value = null;
          },
        );
      },
      (reason) => {
        file.value = null;
      }
    );
  }
  enableTab(tab): boolean {
    return !(tab === 'distribution' && this.formModel.actionNote.stageID === 4);
  }

  importFinancialMatrix(): void {
    const requestBody = {
      productid: this.formModel.actionNote.productID,
      issuerid:this.formModel.actionNote.issuerID,
    };

    this.apiService.importFinancialMatrix(requestBody,  { headers: new HttpHeaders({ 'X-Skip-Loader': 'true' }) }).subscribe((response) => {
      this.formModel.actionNote.financialMatrix = response.data;
      this.revertCompanyStatistics = JSON.parse(JSON.stringify(this.formModel.actionNote.financialMatrix));
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
    });
  }

  importFinancialMatrixManual(): void {
    const requestBody = {
      productid: this.formModel.actionNote.productID,
      issuerid:this.formModel.actionNote.issuerID,
    };

    this.apiService.importFinancialMatrix(requestBody).subscribe((response) => {
      this.formModel.actionNote.financialMatrix = response.data;
      this.revertCompanyStatistics = JSON.parse(JSON.stringify(this.formModel.actionNote.financialMatrix));
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
    });
  }

  revertToPreviousPublishedData():  void {
    this.apiService.revertFinancialMatrixPreviousUpload(this.formModel.actionNote.issuerID).subscribe({
      next: (res)=>{
                this.setFinancialMatrix(res.data);
      },
      complete: ()=>{
        // this.setFinancialMatrix(this.revertCompanyStatisticsClone);
        this.notificationService.showSuccess('Successfully Reverted to Previous Publish Data');
      }
    });
    // 
    // console.log(this.revertCompanyStatisticsClone);
    
  }

  saveDistribution() {
    this.formModel.actionNote.status.distributionStatus = true;

    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.apiService.updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(null, null, this.reset);
  }

  onFinTabChange(event){
    this.selectedFinTab = event;
  }

  clearFinMartix(param){
    if (param === 'companystatistics') {
     this.revertCompanyStatistics.statistics = this.formModel.actionNote.financialMatrix.statistics;
     this.apiService.clearFinancialMatrix(param, this.formModel.actionNote.financialMatrix).subscribe(response =>{
       this.formModel.actionNote.financialMatrix = JSON.parse(JSON.stringify(response));
       this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
     });
    }
    else if (param === 'earningsummary'){
      
      this.revertCompanyStatistics.earnings_values = this.formModel.actionNote.financialMatrix.earnings_values;
      this.revertCompanyStatistics.earnings_parameter = this.formModel.actionNote.financialMatrix.earnings_parameter;
      this.apiService.clearFinancialMatrix(param, this.formModel.actionNote.financialMatrix).subscribe(response =>{
       this.formModel.actionNote.financialMatrix = response;
       
       this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
       this.onEarningGridReady(this.gridParams)
     });
    }
   }

   saveCompanyStatistics(param){
    if(param === 'clear'){
      this.isResetEnabled = true;
      this.clearFinMartix('companystatistics');
    }
    else if(param === 'update'){
      this.onSaveBypassInterceptor();
    }
    else{
      this.onSave();
    }
  }
  saveEarningSummary(param){
    if(param === 'clear'){
      this.isResetEnabled = true;
      this.clearFinMartix('earningsummary');
    }
    else if(param === 'update'){
      this.onSaveBypassInterceptor();
    }
    else{
      this.onSave();
    }
  }
  // companyStatsOriginalRowData: any[] = []; 

   onCompanyGridReady(params) {

    // params.api.forEachNode((node) => {
      
    //   this.companyStatsOriginalRowData.push(_.cloneDeep(node.data));
    // });

    // this.isResetEnabled = false;
    this.companyGridApi = params.api;
    this.companyGridColumnApi = params.columnApi;
    this.companyGridApi.reset = this.resetMatrixValue;
    params.api.sizeColumnsToFit();
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 320;
    /*Get the HOT length*/
    const hotData = this.companyRowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = hotLength * 28 + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }
      
    // this.companyGridStyle.height = `${availableHeight}px`;
  }
  resetMatrix(type): void {
    if (type === 'company') {
      this.formModel.actionNote.financialMatrix.statistics = this.revertCompanyStatistics.statistics;
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
    } else if (type === "earning") {
      this.formModel.actionNote.financialMatrix.earnings_parameter = this.revertCompanyStatistics.earnings_parameter;
      this.formModel.actionNote.financialMatrix.earnings_values = this.revertCompanyStatistics.earnings_values;
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
      this.onEarningGridReady(this.gridParams)
    }
    
  }

   setFinancialMatrix(data, isUpdateRevertData?:any) {
    // if(isUpdateRevertData){
    //   this.revertCompanyStatisticsClone = data;
    // }

    this.companyRowData = data.statistics;
    this.modifiedRowData.companyStatisticsOld = _.cloneDeep(this.companyRowData);
    this.setCurrentPrice(data);
    this.earningRowData = data.earnings_parameter;
    this.modifiedRowData.earningSummaryOld = _.cloneDeep(this.earningRowData);
    this.earningDynamicColums =  data.earnings_parameter_header;
    this.modifiedRowData.earningSummaryDynamicHeaderOld = _.cloneDeep(this.earningDynamicColums); 
    this.earningValues = data.earnings_values;
    this.modifiedRowData.earningSummaryDynamicOld = _.cloneDeep(this.earningValues);
    this.earningYears = _.keysIn(data.earnings_values);
    this.selectedFirstYear = this.selectedLastYear = null;
    this.yearArray = [];
    this.earningDynamicColums.forEach((value) => {
      
      this.yearArray.push(value.periodYear);
      if (value.selected && !this.selectedFirstYear) {
        this.selectedFirstYear = value.periodYear;
        this.modifiedRowData.firstYearOld = this.selectedFirstYear;
      } else if (value.selected) {
        this.selectedLastYear = value.periodYear;
        this.modifiedRowData.lastYearOld = this.selectedLastYear;
      }
    });
  }

  setFairVaule(data){
    let ptp=data.previousTargetPrice;
    let tp = data.targetPrice;
    
    if (ptp && !ptp.toString().includes('.')) {
      ptp = `${ptp}.00`; 
    } else if (ptp && ptp.toString().split('.')[1].length === 1) {
      ptp = `${ptp}0`; 
    }

    if (tp && !tp.toString().includes('.')) {
      tp = `${tp}.00`; 
    } else if (tp && tp.toString().split('.')[1].length === 1) {
      tp = `${tp}0`; 
    }

    // tp = this.validatePriceDecimalWithNumberFormat(tp);

    this.formModel.actionNote.prodIssuers.previousTargetPrice = ptp
    this.formModel.actionNote.prodIssuers.targetPrice = tp;
  }

  setCurrentPrice(data){
       let priceObject = data.statistics.find(stat => stat.parameterName === 'Price'); 
       let currentPrice = priceObject.financialValue;
      
      //  if (currentPrice && !currentPrice.toString().includes('.')) {
      //   currentPrice = `${currentPrice}.00`; 
      // } else if (currentPrice && currentPrice.toString().split('.')[1].length === 1) {
      //   currentPrice = `${currentPrice}0`; 
      // }

      currentPrice = this.validatePriceDecimalWithNumberFormat(currentPrice);
        this.formModel.actionNote.currentPrice  = currentPrice;
        priceObject.financialValue= currentPrice;
  }




   updateFinancialValue = (array, parameter, newValue) => {
    const item = array.find(stat => stat.parameterName === parameter);
    if (item) {
      item.financialValue = newValue;
    }
  };
  
 
  changeYear(event): void {
    this.earningGridApi.setColumnDefs(this.earningColumnDefs.concat(this.getDynamicTable()))
    this.earningGridApi.sizeColumnsToFit();
    this.isResetEnabled = true;
    this.earningDynamicColums.forEach((value) => {
      if(value.periodYear>= this.selectedFirstYear && value.periodYear <= this.selectedLastYear){
          value.selected = true
      }
      else{
        value.selected = false
      }
      
    })
    this.modifiedRowData.lastYear = this.selectedLastYear;
    this.modifiedRowData.firstYear = this.selectedFirstYear;
    this.modifiedRowData.isYearChange = true;
    console.log(this.modifiedRowData);
  }

  getDynamicTable(): any {
    /*Set dynamic clumn headers*/
    let earning_values = this.earningValues;
    const columnHeaders = [];
    
    this.earningDynamicColums.forEach((value) => {
      columnHeaders.push({
        headerName: value.periodYear,
        children: [
          {
            headerName: "Q1",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName))?.q1Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q1Value = params.newValue
                return true
               }
              });
             
               
              
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q2",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).q2Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q2Value = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q3",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).q3Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q3Value = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q4",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).q4Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q4Value = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "FY",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              showRadio: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).fyValue;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.fyValue = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "CY",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              showRadio: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).cyValue;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.cyValue = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
        ],
      });
    });
    
    return columnHeaders;
  }

  getYearArray(event): void {
    return this.yearArray.filter((value) => (event === "firstYear" && value <= this.selectedLastYear) || (event === "lastYear" && value >= this.selectedFirstYear));
  }

  onEarningGridReady(params) {


    params.api.componentParent = this;
    this.gridParams = params;
    // this.isResetEnabled = false;
    this.earningGridApi = params.api;
    this.earningGridColumnApi = params.columnApi;
    this.earningGridApi.setColumnDefs(this.earningColumnDefs.concat(this.getDynamicTable()));
    this.earningGridApi.reset = this.resetMatrixValue;
    params.api.sizeColumnsToFit();
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 370;
    /*Get the HOT length*/
    const hotData = this.earningRowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = hotLength * 28 + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }
    // this.earningGridStyle.height = `${availableHeight}px`;
  

  }

 

  modalRef;
  updateEstimateValuation(content){
    const modalOptions: NgbModalOptions = {
      size: 'xl',
      // windowClass: 'modal-xl',
      // centered: true,
    };
    this.zone.run(() => {
      this.modalRef = this.modalService.open(content, modalOptions);
    });
  }

  updateFinancialMatrix(financialMatrix: any) {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.formModel.actionNote.financialMatrix = financialMatrix;
    this.setFinancialMatrix(financialMatrix, true);
  }

  onCellValueChanged(event: any) {
    let modifiedRow = event.data;
    let originalRow;
    if(this.selectedFinTab == "companyStatistics"){
     originalRow = this.modifiedRowData.companyStatisticsOld.filter(row => row.parameterName === modifiedRow.parameterName);
     this.changedValue(originalRow[0],modifiedRow,this.modifiedRowData.companyStatistics)

    } else if(this.selectedFinTab == "earningSummary"){
      const { data, colDef, column, newValue } = event
      if(column.parent.originalColumnGroup.colGroupDef.headerName){ // dynamic table
        const parameterName = modifiedRow.parameterName
        const year = column.parent.originalColumnGroup.colGroupDef.headerName;
         modifiedRow = this.earningValues[year].filter(item => item.parameterName === parameterName)
         originalRow = this.modifiedRowData.earningSummaryDynamicOld[year].filter(item => item.parameterName === parameterName)
        this.changedValue(originalRow[0],modifiedRow[0],this.modifiedRowData.earningSummaryDynamic, true)

      } else { // static table
        originalRow = this.modifiedRowData.earningSummaryOld.filter(row => row.parameterName === modifiedRow.parameterName);
        this.changedValue(originalRow[0],modifiedRow,this.modifiedRowData.earningSummary)
      }
    }  
    console.log(this.modifiedRowData);
  }
  formatNumberFormatInput(fieldPath: string, currentValue: any): void {
    const formattedValue = this.validatePriceDecimalWithNumberFormat(currentValue);
    set(this, fieldPath, formattedValue); 
  }


  onActionCellClicked(modifiedRow){ // dynamic header change
    let originalRow = this.modifiedRowData.earningSummaryDynamicHeaderOld.filter(row => row.periodYear === modifiedRow.periodYear);
    this.changedValue(originalRow[0],modifiedRow,this.modifiedRowData.earningSummaryDynamicHeader, true)
    console.log(this.modifiedRowData)
  }

  changedValue(originalRow,modifiedRow, changedRow, isDynamic?:boolean){

    let updatedFields;
    let existingIndex;
    const changedFields = Object.keys(modifiedRow).filter(
      key =>  modifiedRow[key] !== undefined && modifiedRow[key] !== originalRow[key]
    );
    if(isDynamic){
      if(modifiedRow.parameterName){
        this.changeEarningValueDynamic(originalRow, modifiedRow,changedRow)
        return ;
      } else {
        updatedFields = {
          [modifiedRow.periodYear] : changedFields
        }
        existingIndex = changedRow.findIndex(row =>
          row.hasOwnProperty(modifiedRow.periodYear)
        );
      }
      
    }else {
       updatedFields = {
        [modifiedRow.parameterName] : changedFields
      }
      existingIndex = changedRow.findIndex(row =>
        row.hasOwnProperty(modifiedRow.parameterName)
      );
    }
    if (changedFields.length === 0) {
      if (existingIndex !== -1) {
        changedRow.splice(existingIndex, 1); 
      }
    } else {
      if (existingIndex !== -1) {
        changedRow[existingIndex] = updatedFields;
      } else {
        changedRow.push(updatedFields);
      }
    }
  }

  changeEarningValueDynamic(originalRow, modifiedRow, changedRow) {
    const changedFields = Object.keys(modifiedRow).filter(
      key => modifiedRow[key] !== undefined && modifiedRow[key] !== originalRow[key]
    );
    const updatedFields = {
      [modifiedRow.parameterName]: changedFields,
    };
    const yearwise = {
      [modifiedRow.periodYear]: [updatedFields],
    };  
  
    const existingIndex = changedRow.findIndex(row =>
      row.hasOwnProperty(modifiedRow.periodYear)
    );
  
    if (existingIndex !== -1) {
      const existingYear = changedRow[existingIndex];
      const innerArray = existingYear[modifiedRow.periodYear]; 
  
      const existingIndexInner = innerArray.findIndex(innerRow =>
        innerRow.hasOwnProperty(modifiedRow.parameterName)
      );
  
      if (existingIndexInner !== -1) {
        if (changedFields.length === 0) {
          innerArray.splice(existingIndexInner, 1);
        } else {
          innerArray[existingIndexInner] = updatedFields;
        }
      } else if (changedFields.length > 0) {
        innerArray.push(updatedFields);
      }
  
      if (innerArray.length === 0) {
        changedRow.splice(existingIndex, 1);
      }
    } else if (changedFields.length > 0) {
      changedRow.push(yearwise);
    }
  }
  
}
