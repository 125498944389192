import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../_services';
import _ from 'lodash';

@Component({
  templateUrl: 'distributor.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class DistributorAdminComponent implements OnInit {
  private gridApi: { sizeColumnsToFit: () => void; getSelectedRows: () => any[]; getRowNode: (arg0: any) => any; };
  distributionDef = [];
  distributionList = [];
  rowHeight = 50;
  paginationPageSize = 10;
  rowSelection = 'single';
  selectedRow: any;
  gridStyle = {
    height: '400px'
  };
  columnDefs = [
    {
      headerName: 'Display name',
      field: 'typeName',
      width: 500,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Type',
      field: 'typeValue',
      width: 500,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }
  ];

  resetHeight() {
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    /*Get the HOT length*/
    const hotData = this.distributionDef || [];
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * this.rowHeight) + 55;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  onGridReady(params: { api: { sizeColumnsToFit: () => void; getSelectedRows: () => any[]; getRowNode: (arg0: any) => any; }; }) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.resetHeight();

  }

  getRowNodeId({ typeID }) {
    return typeID;
  }

  onSelectionChanged() {
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
    this.adminService.getDistribution(this.selectedRow.typeID).subscribe(({ data }) => {
      this.distributionList = data;
    });
  }

  onSaveDistributor() {
    this.adminService.saveDistributorRecipients(this.selectedRow.typeID, this.distributionList).subscribe();
  }

  constructor(
    private adminService: AdminService
  ) {

  }

  ngOnInit() {
    this.adminService.getAllDistributions().subscribe(({ data }) => {
      this.distributionDef = data;
      this.resetHeight(); //
    });
  }
}