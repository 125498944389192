import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../../_services';
import * as moment from 'moment';
import {Router} from '@angular/router';
import _ from 'lodash';

@Component({
  templateUrl: 'industry.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class IndustryAdminComponent implements OnInit {
  private gridApi;
  createView = false;
  industryDef = [];
  industry = {};
  rowHeight = 40;
  paginationPageSize = 20;
  rowSelection = 'single';
  selectedRow: any;
  monthsList = moment.months();
  actionLabel = 'Update';
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'IndustryID',
      field: 'industry',
      width: 560,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Industry Name',
      field: 'industryName',
      width: 560,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Mailing address',
      field: 'mailingListPrefix',
      width: 560,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }
  ];


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  getRowNodeId({ industry }) {
    return industry;
  }

  onSelectionChanged() {
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
  }

  onSaveIndustry() {
    this.adminService.saveIndustry(this.createView ? 'createindustry' : 'updateindustry',this.selectedRow).subscribe(() => {
      if (!this.createView) {
        const rowNode = this.gridApi.getRowNode(this.selectedRow.industry);
        rowNode.setData(this.selectedRow);
      }
    });
  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  constructor(
    private adminService: AdminService,
    private router: Router
  ) {

  }

  resetHeight() {
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    if ( window.innerHeight > 1000 ) {
      availableHeight = window.innerHeight - 600;
    }
    /*Get the HOT length*/
    const hotData = this.industryDef || [];
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * this.rowHeight) + 50;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  ngOnInit() {
    this.createView = this.router.url === '/industries/newIndustry';
    if (this.createView) {
      this.selectedRow = {
        isActive: true,
        industryName: "",
        mailingListPrefix: ""
      };
      this.actionLabel = 'Save';
    };
    this.adminService.getAllIndustries().subscribe(({data}) => {
        this.industryDef = data;
        this.resetHeight();
    });
  }
}
