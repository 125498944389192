export let panels = [
  // Add Panel for creating new documents
  {
    icon: 'fa-file-text-o',
    iconUrl: '/documents',
    name: 'Company Documents',
    description: 'This page for creating new company reports',
    options: [
      {
        optionName: 'Company Report',
        optionUrl: '/companyreport/create',
        enabled: true
      }
    ]
  },
  // Add Other Documents Panel
  {
    icon: 'fa-file-text-o',
    iconUrl: '/documents',
    name: 'Industry Documents',
    description: 'This page for creating other new documents',
    options: [
      {
        optionName: 'Industry Comment',
        optionUrl: '/industryreport/create',
        enabled: true
      },
      {
        optionName: 'Industry Comment With Rating',
        optionUrl: '/industrywithratingreport/create',
        enabled: true
      }
    ]
  },
];

export const unit = {
  percentage: '%',
  "s000": '(000)',
  B: '(B)',
  bd: '(b/d)',
  boed: '(boe/d)',
  Cb: '(C$/b)',
  Cmcf: '(C$/mcf)',
  exGainsLossesandSLRents: '(ex. Gains/Losses and SL Rents)',
  exGainsLosses: '(ex. Gains/Losses)',
  inferred: '(Inferred)',
  MI: '(M&I)',
  M: '(M)',
  mmcfd: '(mmcf/d)',
  Reported: '(Reported)',
  USb: '(US$/b)',
  Uslb: '(US$/lb)',
  USoz: '(US$/oz)',
  Ustonne: '(US$/tonne)',
  bbl: '/bbl',
  boe: '/boe',
  oz: '/oz',
  shr: '/shr',
  unit: '/unit',
  oz000: '000oz',
  mo12: '12-mo',
  adj: 'adj.',
  basic: 'basic',
  basicplusdiluted: 'basic + diluted',
  basicplusexch: 'basic + exch.',
  cash: 'cash',
  current: 'current',
  diluted: 'diluted',
  fd: 'fd',
  float: 'float',
  implied: 'implied',
  Inventory: 'Inventory',
  LT: 'LT',
  Moz: 'Moz',
  Mt: 'Mt',
  net: 'net',
  normalized: 'normalized',
  PPReserve: 'PPReserve',
  Production: 'Production',
  reported: 'reported',
  Resources: 'Resources',
  total: 'total',
  x: '(x)',
  Custom1: '',
  Custom2: '',
  Custom3: '',
  Custom4: '',
  Custom5: '',
  Custom6: '',
  Custom7: '',
  Custom8: '',
  Custom9: '',
  Custom10: ''
};

export let currencies = [
  '',
  'CAD',
  'USD',
  'EUR',
  'GBP',
  'GBp',
  'CHF',
  'SEK',
  'DKK',
  'NOK',
  'CNY',
  'JPY',
  'KRW',
  'HKD',
  'TWD',
  'SGD',
  'AUD',
  'ZAR',
  'BRL',
];
export let companyColumnDefs = [
  {
    headerName: 'Sequence',
    field: 'sequence',
    width: 80,
    editable: true,
    valueParser: function (params) {
      return Number(params.newValue);
    },
    cellEditor: 'agTextCellEditor'
  },
  {
    headerName: 'Unit1',
    field: 'unit1DisplayName',
    // unitName: 'unit1Name',
    width: 150,
    editable: function (params) {
      return params.data.displayNameEditable;
    },
    cellRenderer: 'unitRenderer'
  },
  {
    headerName: 'Unit2',
    field: 'unit2DisplayName',
    // unitName: 'unit2Name',
    width: 150,
    editable: function (params) {
      return params.data.displayNameEditable;
    },
    cellRenderer: 'unitRenderer'
  },
  {
    headerName: 'Currency',
    field: 'currencyName',
    width: 130,
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: currencies
    }
  },
  {
    headerName: 'R?',
    field: 'researchNoteSelected',
    width: 80,
    cellRenderer: 'checkBoxRenderer'
  },
  {
    headerName: 'SnowFlake',
    field: 'snowFlakeSyncSelected',
    minWidth: 100,
    cellRenderer: 'checkBoxRenderer',    
  },
  
  {
    headerName: 'Name',
    field: 'parameterName',
    width: 250
  },
  {
    headerName: 'Display Name',
    field: 'displayName',
    width: 250,
    cellEditor: 'agTextCellEditor',
    editable: function (params) {
      return params.data.displayNameEditable;
    }
  },
  {
    headerName: 'Value',
    field: 'financialValue',
    width: 100,
    cellEditor: 'agTextCellEditor',
    editable: true
  }
];

export let fixedEarningSummaryColumns = [
  {
    children: [{
      headerName: 'Seq',
      field: 'sequence',
      minWidth: 70,
      editable: true,
      pinned: true,
      valueParser: function (params) {
        return Number(params.newValue);
      },
      cellEditor: 'agTextCellEditor'
    },
      {
        headerName: 'Unit1',
        field: 'unit1DisplayName',
        unitName: 'unit1Name',
        minWidth: 110,
        pinned: true,
        editable: false,
        cellRenderer: 'unitRenderer'
      },
      {
        headerName: 'Unit2',
        field: 'unit2DisplayName',
        unitName: 'unit2Name',
        minWidth: 110,
        pinned: true,
        editable: false,
        cellRenderer: 'unitRenderer'
      },
      {
        headerName: 'Currency',
        field: 'currencyName',
        minWidth: 80,
        editable: true,
        pinned: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: currencies
        }
      },
      {
        headerName: 'RA?',
        field: 'researchNoteAnnuallySelected',
        minWidth: 50,
        pinned: true,
        cellRenderer: 'checkBoxRenderer'
      },
      {
        headerName: 'RQ?',
        field: 'researchNoteQuarterlySelected',
        minWidth: 50,
        pinned: true,
        cellRenderer: 'checkBoxRenderer'
      },
      {
        headerName: 'E/V',
        field: 'estimatesValuationDisplay',
        minWidth: 50,
        pinned: true,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['E', 'V']
        },    

      },
      {
        headerName: 'SnowFlake',
        field: 'snowFlakeSyncSelected',
        minWidth: 100,
        pinned: true,
        cellRenderer: 'checkBoxRenderer',    
      },
      // {
      //   headerName: 'PA?',
      //   field: 'portalAnnuallySelected',
      //   minWidth: 50,
      //   pinned: true,
      //   cellRenderer: 'checkBoxRenderer'
      // },
      // {
      //   headerName: 'PQ?',
      //   field: 'portalQuarterlySelected',
      //   minWidth: 50,
      //   pinned: true,
      //   cellRenderer: 'checkBoxRenderer'
      // },
      {
        headerName: 'Display Name',
        field: 'displayName',
        minWidth: 150,
        cellEditor: 'agTextCellEditor',
        editable: function (params) {
          return params.data.displayNameEditable;
        },
        pinned: true,
      },
      {
        headerName: 'Name',
        field: 'parameterName',
        minWidth: 150,
        pinned: true,
      }]
  }

];
